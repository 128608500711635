import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Text, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { AppButton } from '@Components/Control';

import { AddButton } from './ProductFormComponent';
import { StyledUploadContainer, StyledUploadInput } from './StyledProductFormComponent';


const extractFiles = (data) => data.filter((dt) => dt.added).map((dt) => dt.file);
const renderNewFile = () => ({ id: uniqid(), file: null, added: false });

const MediaUpload = ({
  small,
  maxInputs = 1,
  handleFormValues,
  authPagesConfig: {
    focusHighlight,
  },
  inputConfig = null,
}) => {
  const [inputs, setInputs] = React.useState([renderNewFile()]);

  const addInput = () => {
    const prevInputs = [...inputs];

    if (prevInputs[prevInputs.length - 1].file) {
      const addInputs = [...prevInputs];
      addInputs[addInputs.length - 1].added = true;

      if (!maxInputs || addInputs.length < maxInputs) {
        addInputs.push(renderNewFile());
      }

      setInputs(addInputs);
      handleFormValues(extractFiles(inputs));
    }
  };

  const removeInput = (index) => {
    const prevInputs = [...inputs];
    const remInputs = prevInputs.filter((_, i) => i !== index);
    const { length } = remInputs;

    if (remInputs[length - 1].added) {
      remInputs.push(renderNewFile());
    }

    setInputs(remInputs);
    handleFormValues(extractFiles(inputs));
  };

  const handleFile = (index, e) => {
    const fileInputs = [...inputs];
    [fileInputs[index].file] = e.currentTarget.files;

    setInputs(fileInputs);
  };

  let acceptedFileTypes = 'image/*, video/*';

  if (inputConfig && inputConfig.acceptedFileType) {
    acceptedFileTypes = inputConfig.acceptedFileType.join(',');
  }

  return (
    <Box gap="small">
      {inputs.map(({ id, file, added }, i) => (
        <Box key={id} gap="small">
          <Box direction="row" gap="2rem" width={small ? '100%' : '90%'}>
            <StyledUploadContainer highlight={focusHighlight}>
              <Box fill gap="1rem" align="center" direction="row">
                <Text truncate size="0.875rem" textAlign="end" className={!file ? 'preline' : null}>
                  {file ? file.name : 'No image or video file selected'}
                </Text>
                <AppButton
                  level="xsmall"
                  disabled={added}
                  label="Choose File..."
                  color={focusHighlight}
                />
              </Box>
              <StyledUploadInput
                disabled={added}
                type="file"
                accept={acceptedFileTypes}
                onChange={(e) => handleFile(i, e)}
                highlight={focusHighlight}
              />
            </StyledUploadContainer>
            {added
              && (
              <Button
                plain
                icon={<FormClose size="1.25rem" color="#636363" />}
                onClick={() => removeInput(i)}
              />
              )}
          </Box>
          {!added && (
            <AddButton disabled={!file} onClick={() => addInput()} highlight={focusHighlight} />
          )}
        </Box>
      ))}
    </Box>
  );
};

MediaUpload.propTypes = {
  maxInputs: PropTypes.number,
  handleFormValues: PropTypes.func.isRequired,
  authPagesConfig: PropTypes.shape({
    focusHighlight: PropTypes.string.isRequired,
  }).isRequired,
  inputConfig: PropTypes.shape({
    acceptedFileType: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  small: PropTypes.bool.isRequired,
};

export default MediaUpload;
