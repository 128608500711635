import {
  PRODUCT_EMBED_ACCESS_DATA_LOADED,
  PRODUCT_EMBED_ACCESS_DATA_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  embedAccessToken: null,
  embedAccessTokenExpiration: null,
  embedCustomerId: null,
};

const productEmbed = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_EMBED_ACCESS_DATA_LOADED:
      return {
        ...state,
        embedAccessToken: action.payload.accessData.access_token,
        embedAccessTokenExpiration: action.payload.accessData.expires_in,
        embedCustomerId: action.payload.accessData.customer,
      };

    case PRODUCT_EMBED_ACCESS_DATA_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default productEmbed;
