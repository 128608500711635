import { secondsSinceEpoch } from '@Helpers/util';

import {
  PRODUCT_EMBED_ACCESS_DATA_LOADED,
  PRODUCT_EMBED_ACCESS_DATA_CLEAR,
} from '../actionTypes';

import { getAbortControllerSignal } from '../aborterHelper';
import { setFetchLoaderLoading, setFetchLoaderReset } from '../fetchLoaderActions';
import { arriveNotice } from '../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../faviconActions';

// API helper
async function makeDomoRequest(method, path, body, authorization) {
  const signal = getAbortControllerSignal();
  const options = {
    method,
    signal,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: authorization,
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(`https://api.domo.com/${path}`, options);

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response;
}

// Reducer corresponding actions
function embedAccessDataLoaded(accessData) {
  return {
    type: PRODUCT_EMBED_ACCESS_DATA_LOADED,
    payload: { accessData },
  };
}

function embedAccessDataClear() {
  return { type: PRODUCT_EMBED_ACCESS_DATA_CLEAR };
}

// API
async function accessDataApi() {
  const authorization = `Basic ${process.env.DOMO_ENCODED_KEY}`;
  const response = await makeDomoRequest('GET', 'oauth/token?grant_type=client_credentials', null, authorization);

  return response.json();
}

// Helper
async function fetchEmbedAccessData(dispatch) {
  try {
    const response = await accessDataApi();

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(embedAccessDataLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function refreshEmbedAccessDataRequest() {
  return async (dispatch, getState) => {
    const {
      productEmbed: { embedAccessToken, embedAccessTokenExpiration },
      fetchLoader: { dataLoading },
    } = getState();

    if (embedAccessToken && embedAccessTokenExpiration
      && embedAccessTokenExpiration - 60 > secondsSinceEpoch()) {
      return;
    }

    dispatch(embedAccessDataClear());
    dispatch(setFaviconLoading());
    if (!dataLoading) dispatch(setFetchLoaderLoading());

    await fetchEmbedAccessData(dispatch);
  };
}

export function clearEmbedAccessDataRequest() {
  return (dispatch) => {
    dispatch(embedAccessDataClear());
  };
}
