import {
  PAYMENT_TIER_LOADED,
  PAYMENT_TIER_CLEAR,
  CHECKOUT_REQUESTED,
  CHECKOUT_CLEAR,
} from './actionTypes';

import * as api from './apiHelper';
import { initiateRedirectRequest } from './redirectActions';
import { arriveNotice } from './noticeActions';

function paymentTierLoaded(tier) {
  return {
    type: PAYMENT_TIER_LOADED,
    payload: { tier },
  };
}

function paymentTierClear() {
  return { type: PAYMENT_TIER_CLEAR };
}

function checkoutLoading() {
  return { type: CHECKOUT_REQUESTED };
}

function checkoutClear() {
  return { type: CHECKOUT_CLEAR };
}

async function getPaymentTierApi(products, tier) {
  const response = await api.get(`products/${products}/tiers/${tier}`, null, null, 'v2');
  return response.json();
}

async function createCheckoutApi(products, body, token) {
  const response = await api.post(`products/${products}/subscriptions`, body, token, 'v2');
  return response.json();
}

export function clearPaymentTierRequest() {
  return (dispatch) => {
    dispatch(paymentTierClear());
  };
}

export function fetchPaymentTierRequest(products, tier, pricingPath, handleState) {
  return async (dispatch) => {
    if (tier) {
      try {
        dispatch(paymentTierClear());

        const response = await getPaymentTierApi(products, tier);
        dispatch(paymentTierLoaded(response));
      } catch (e) {
        if (pricingPath && e.name !== 'AbortError') {
          dispatch(arriveNotice(e.message));
          dispatch(initiateRedirectRequest(null, pricingPath, true));
        }
      }
    } else if (pricingPath) {
      dispatch(initiateRedirectRequest(null, pricingPath, true));
    } if (handleState) {
      handleState();
    }
  };
}

export function checkoutRequest(products, { uid }, stripeInfo, rootPath) {
  return async (dispatch, getState) => {
    const { user: { token } } = getState();

    try {
      dispatch(checkoutLoading());

      const result = await stripeInfo.stripe.createToken(stripeInfo.card);

      if (result.error) {
        throw new Error(result.error.message);
      } else {
        const body = { token: result.token.id, tier: { uid } };
        const { notice } = await createCheckoutApi(products, body, token);

        dispatch(checkoutClear());
        dispatch(arriveNotice(notice, 'ok'));
        dispatch(initiateRedirectRequest(null, rootPath));
      }
    } catch (e) {
      dispatch(checkoutClear());
      dispatch(arriveNotice(e.message));
    }
  };
}
