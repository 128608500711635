import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'grommet';


const InfoNotice = ({
  header,
  headerColor,
  text,
  textColor,
  backgroundColor,
  borderColor,
}) => (
  <Box
    background={backgroundColor}
    border={{
      color: borderColor, size: '1px', side: 'all', style: 'solid',
    }}
    round="10px"
    direction="column"
    align="start"
    justify="center"
    gap="0.4rem"
    pad={{ vertical: '0.65rem', horizontal: '1rem' }}
    margin={{ horizontal: '2rem', bottom: '1rem' }}
  >
    <Text color={headerColor} weight={600} size="0.9rem">{header}</Text>
    <Text color={textColor} size="0.9rem">{text}</Text>
  </Box>
);

InfoNotice.propTypes = {
  header: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
};

export default InfoNotice;
