import styled, { css } from 'styled-components';
import { Box } from 'grommet';

const arrowStyle = css`
  position: absolute;
  width: 0.9rem;
  height: 0.9rem;
`;

const DirectedArrow = styled.div`
  ${arrowStyle}
  ${({ $front }) => !$front && 'z-index: -1;'}

  &::before {
    ${arrowStyle}
    content: '';
    background: ${({ color }) => color ?? 'white'};
    transform: rotate(45deg);
  }
`;

const Container = styled(Box)`
  z-index: 1;
  ${({ shadow }) => shadow && `box-shadow: ${shadow};`}

  ${DirectedArrow}:before {
    ${({ shadow }) => shadow && `box-shadow: ${shadow};`}
  }

  &[data-popper-placement='bottom'] {
    ${({ offset }) => offset && `top: ${offset} !important;`}

    > ${DirectedArrow} {
      top: calc(${({ arrowDepth }) => `-0.55rem + ${arrowDepth || '0'}`});
      ${({ border }) => border && `
        &:before {
          border-top: ${border.size} solid ${border.color};
          border-left: ${border.size} solid ${border.color};
        }
      `}
    }
  }

  &[data-popper-placement='bottom-end'] {
    ${({ offset }) => offset && `top: ${offset} !important;`}

    > ${DirectedArrow} {
      top: calc(${({ arrowDepth }) => `-0.55rem + ${arrowDepth || '0'}`});
      ${({ border }) => border && `
        &:before {
          border-top: ${border.size} solid ${border.color};
          border-left: ${border.size} solid ${border.color};
        }
      `}
    }
  }

  &[data-popper-placement='bottom-start'] {
    ${({ offset }) => offset && `top: ${offset} !important;`}

    > ${DirectedArrow} {
      top: calc(${({ arrowDepth }) => `-0.55rem + ${arrowDepth || '0'}`});
      ${({ border }) => border && `
        &:before {
          border-top: ${border.size} solid ${border.color};
          border-left: ${border.size} solid ${border.color};
        }
      `}
    }
  }

  &[data-popper-placement^='top'] {
    ${({ offset }) => offset && `bottom: ${offset} !important;`}

    > ${DirectedArrow} {
      bottom: calc(${({ arrowDepth }) => `-0.45rem + ${arrowDepth || '0'}`});
      ${({ border }) => border && `
        &:before {
          border-bottom: ${border.size} solid ${border.color};
          border-right: ${border.size} solid ${border.color};
        }
      `}
    }
  }
`;

export default { DirectedArrow, Container };
