import { combineReducers } from 'redux';
import redirect from './redirectReducer';
import payment from './paymentReducer';
import auth from './product/authReducer';
import productNewForm from './product/newReducer';
import productList from './product/listReducer';
import productDashboard from './product/dashboardReducer';
import productEmbed from './product/embedReducer';
import notice from './noticeReducer';
import favicon from './faviconReducer';
import appLoader from './appLoaderReducer';
import fetchLoader from './fetchLoaderReducer';

const rootReducers = combineReducers({
  redirect,
  payment,
  auth,
  productNewForm,
  productList,
  productDashboard,
  productEmbed,
  notice,
  favicon,
  appLoader,
  fetchLoader,
});

export default rootReducers;
