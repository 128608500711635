import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Layer } from 'grommet';

import Spinning from './Spinning';

const TransparentLayer = styled(Layer)`background: transparent;`;

const AppLoader = ({ loading }) => {
  if (!loading) return null;

  return (
    <TransparentLayer animate={false} responsive={false}>
      <Spinning size="xlarge" />
    </TransparentLayer>
  );
};

function mapStateToProps(state) {
  return { loading: state.appLoader.loading };
}

AppLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(AppLoader);
