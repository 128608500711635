import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import { StyledFormTextArea } from './StyledProductFormComponent';

const trimArrayParser = (text) => text.trim().split(/\s*,\s*/).filter((d) => d.trim() !== '');

const TextAreaInput = ({
  charLimit = null,
  handleFormValues,
  authPagesConfig: {
    focusHighlight,
  },
}) => {
  const handleChange = (val) => {
    const inputsArr = trimArrayParser(val).slice(-charLimit);
    handleFormValues(inputsArr);
  };

  return (
    <Box width="100%">
      <StyledFormTextArea
        fill
        highlight={focusHighlight}
        resize="vertical"
        rows="5"
        onChange={(e) => handleChange(e.target.value)}
        maxlength={charLimit}
      />
    </Box>
  );
};

TextAreaInput.propTypes = {
  charLimit: PropTypes.number,
  handleFormValues: PropTypes.func.isRequired,
  authPagesConfig: PropTypes.shape({
    focusHighlight: PropTypes.string.isRequired,
  }).isRequired,
};

export default TextAreaInput;
