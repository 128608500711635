import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

const Container = ({ forwardRef = null, ...rest }) => (
  <Box alignSelf="center" width="2560px" ref={forwardRef} {...rest} />
);

Container.propTypes = {
  forwardRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default Container;
