import {
  NOTICE_LOADED,
  NOTICE_CLEAR,
} from './actionTypes';

// Reducer corresponding actions
function noticeLoaded(message, type) {
  return {
    type: NOTICE_LOADED,
    payload: { message, type },
  };
}

function noticeClear() {
  return { type: NOTICE_CLEAR };
}

// Public components actions
export function closeNoticeRequest() {
  return (dispatch) => {
    dispatch(noticeClear());
  };
}

export function arriveNotice(message, type = 'warning', clear = true) {
  return (dispatch) => {
    if (clear) dispatch(noticeClear());
    dispatch(noticeLoaded(message, type));
  };
}
