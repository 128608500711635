import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import uniqid from 'uniqid';

import {
  Box, Text, Image,
} from 'grommet';

import { Seo, PercentageMeter } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { CurrentDateContext } from '@Components/Context';
import { AuthNavWrapper } from '@Components/Navigation';

import { StreamDataTable } from '../../../Partial/Product/CyclopsComponents';
import {
  StyledCyclopsHeading,
} from './StyledCyclopsComponents';


const CyclopsEventDetailsPage = ({
  small,
  mixpanel,
  location,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyCyclopsData = null,
}) => {
  const { id: eventUid } = useParams();
  const { today } = React.useContext(CurrentDateContext);
  const formattedDate = today.toLocaleDateString('en-US', {
    month: 'short', day: '2-digit', year: 'numeric',
  });

  const {
    darkModeColors: {
      primaryDarkBg, containerBg, primaryText, secondaryText,
    },
  } = authPagesConfig;
  let eventData = null;
  let deviceColorMap = null;

  if (companyCyclopsData) {
    eventData = companyCyclopsData.metadata.config.eventsData.events.find(
      ({ uid }) => uid === Number(eventUid),
    );
    deviceColorMap = companyCyclopsData.metadata.config.eventsData.deviceColorMap;
  }

  if (!eventData) return null;

  const renderVisualizations = (title, value, values, vizType, placeholder) => {
    switch (vizType) {
      case 'activityMap':
        return (
          <Box direction="column" gap="1rem">
            <Text size="1rem" weight={500} color={primaryText}>{title}</Text>
            {value && (
              <Box direction="row" gap="0.5rem" align="end">
                <Text size="1.5rem" weight={600} color={primaryText}>{value}</Text>
              </Box>
            )}
            <Image src={placeholder} fit="contain" />
          </Box>
        );

      case 'deviceBreakdown':
        return (
          <Box flex direction="column" gap="1rem">
            <Text size="1rem" weight={500} color={primaryText}>{title}</Text>
            <PercentageMeter
              flex
              align="center"
              justify="center"
              small={small}
              values={values}
              colorMap={deviceColorMap}
              direction="horizontal"
            />
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box flex background={primaryDarkBg} pad={small ? '2rem' : '3rem'} direction="column">
        <StyledCyclopsHeading level={2} color={primaryText}>
          {eventData.title}
        </StyledCyclopsHeading>
        <Box pad={{ top: '1rem' }}>
          <Text color={secondaryText} size="1rem" weight={500}>
            {eventData.status === 'live' ? formattedDate : eventData.eventDate}
          </Text>
        </Box>
        <Box wrap direction="row">
          {eventData?.visualizations?.map(({
            title, value, values, vizType, placeholder,
          }, i) => (
            <Box
              basis={small ? 'full' : '1/2'}
              key={uniqid()}
              height={small ? '20rem' : '22rem'}
              pad={{ vertical: '1rem', right: (small || i === eventData.visualizations.length - 1) ? '0' : '2rem' }}
            >
              <Box
                flex
                background={containerBg}
                direction="column"
                gap="1rem"
                pad="2rem"
                width="100%"
              >
                {renderVisualizations(title, value, values, vizType, placeholder)}
              </Box>
            </Box>
          ))}
        </Box>
        <Box pad={{ top: '1rem' }}>
          <StreamDataTable
            small={small}
            tableTitle="Piracy Streams"
            streamData={eventData.streamData}
            highlightColor={secondaryText}
            containerBg={containerBg}
            textColor={primaryText}
            dividerColor="#E5E9EB"
          />
        </Box>
      </Box>
    </AuthNavWrapper>
  );
};

CyclopsEventDetailsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyCyclopsData: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        companyName: PropTypes.string.isRequired,
        eventsData: PropTypes.shape({
          deviceColorMap: PropTypes.shape({
            mobile: PropTypes.string.isRequired,
            desktop: PropTypes.string.isRequired,
            tablet: PropTypes.string.isRequired,
          }).isRequired,
          events: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            uid: PropTypes.number.isRequired,
            category: PropTypes.string.isRequired,
            eventDate: PropTypes.string,
            image: PropTypes.string.isRequired,
            visualizations: PropTypes.arrayOf(PropTypes.shape({
              title: PropTypes.string.isRequired,
              value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
              ]),
              values: PropTypes.arrayOf(PropTypes.shape({
                display: PropTypes.string.isRequired,
                uid: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
              }).isRequired),
              vizType: PropTypes.string.isRequired,
              placeholder: PropTypes.string,
            }).isRequired).isRequired,
            streamData: PropTypes.arrayOf(PropTypes.shape({
              streamUrl: PropTypes.string.isRequired,
              detectionTime: PropTypes.string.isRequired,
              viewers: PropTypes.string.isRequired,
            }).isRequired).isRequired,
          }).isRequired).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

export default withProductAuth(CyclopsEventDetailsPage);
