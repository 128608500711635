import React from 'react';
import styled from 'styled-components';

import { ReactSVG } from 'react-svg';
import { DropButton } from '@Components/Control';
import {
  Box, Text, TextInput, Button, RangeSelector,
} from 'grommet';

const StyledText = styled(Text)`
  line-height: 1.25;
`;

const StyledTextInput = styled(TextInput)`
  background: white;
  font-size: 0.8rem;
  border: solid 1px #D0D0D0;
  padding: 0.4rem 0.6rem;
  border-radius: 0.35rem;
  width: 12rem;

  ${({ withIcon }) => withIcon && `
    padding-left: 35px;
  `}

  &:focus {
    border-color: ${({ focusHighlight }) => focusHighlight};
  }

  @media (max-width: 768px) {
    width: 14.5rem;
  }
`;

const StyledRaisedContainer = styled(Box)`
  border-radius: ${({ noRound }) => (noRound ? '0' : '0.35rem')};
  box-shadow: ${({ noShadow }) => (noShadow ? 'none' : '0px 2px 7px 0px rgba(0, 0, 0, 0.12)')};
  background-color: ${({ background }) => background || 'white'};
`;

const StyledSVG = styled(ReactSVG)`
  svg {
    height: ${({ height }) => height};
    width:${({ width }) => width};
  }
`;

const StyledHoverContainer = styled(Box)`
  &:hover {
    svg > path {
      fill: ${({ hoverColor }) => hoverColor};
    }
  }
`;

const StyledSimpleDrop = styled((props) => <DropButton {...props} />)`
  border-radius: 0.35rem;

  > button {
    background: white;
    font-size: 0.8rem;
    padding: 0.3rem;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    width: ${({ larger }) => (larger ? '4.5rem' : '3.15rem')};
    height: ${({ larger }) => (larger ? '1.85rem' : '1.6rem')};

    &:focus {
      border: ${({ highlight }) => `1px solid ${highlight}`};
    }

    > div {
      border-radius: 0.35rem;
      width: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center;
    }
  }

  ${({ larger }) => larger && `
    svg {
      padding-top: 0.25rem;
    }
  `}
`;

const StyledFlexibleDrop = styled((props) => <DropButton {...props} />)`
  border-radius: 0.35rem;

  > button {
    background: ${({ bgColor }) => bgColor ?? 'white'};
    font-size: 0.8rem;
    padding: 0.3rem;
    border-radius: 5px;
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '1px solid #D0D0D0')};
    min-width: ${({ width }) => width};
    min-height: ${({ height }) => height};

    &:focus {
      border: ${({ highlight }) => `1px solid ${highlight}`};
    }

    > div {
      border-radius: 0.35rem;
      width: 100%;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center;
    }
  }
`;

const StyledPaginationArrow = styled((props) => <Button {...props} />)`
  border-radius: 5px;
  border: 1px solid #D0D0D0;
  background: white;
  padding: 0.15rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border: ${({ highlight }) => `1px solid ${highlight}`};
  }
`;

const StyledSelectMenu = styled(Button)`
  padding: 0.5rem;
  border-radius: 6px;

  &:hover {
    color: ${({ highlightText }) => highlightText};
  }
`;

const StyledSearchInputClear = styled(Button)`
  position: absolute;
  padding-right: 0.5rem;
  top: 0;
  bottom: 0;
  right: 0;
`;

const StyledRangeSelect = styled(RangeSelector)`
  span {
    width: 3rem;
    min-width: 3rem;
  }
`;

const StyledIconButton = styled(Button)`
  border-radius: 5px;
  padding: 0.25rem 0.6rem;
  background: ${({ bgColor }) => bgColor ?? 'white'};
  font-weight: 600;
  margin: ${({ noMargin }) => (noMargin ? 'none' : '0.25rem')};
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  StyledText,
  StyledTextInput,
  StyledRaisedContainer,
  StyledSVG,
  StyledHoverContainer,
  StyledSimpleDrop,
  StyledFlexibleDrop,
  StyledSelectMenu,
  StyledPaginationArrow,
  StyledSearchInputClear,
  StyledRangeSelect,
  StyledIconButton,
};
