import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import uniqid from 'uniqid';
import { isSafari, isEdge } from 'react-device-detect';

import { localAsset } from '@Helpers/asset';
import documentHead from '@Helpers/documentHead';

const seoImage = localAsset('images/logo/logo-full-color.svg');
const seoUrl = (path) => `${window.location.origin}${path}`;

const getMetaTags = ({
  title, description, path, customImage, contentType, published, updated, category, tags,
}) => {
  const metaTags = [
    { name: 'description', content: description },
    { name: 'twitter:card', content: 'summary_large_image' },
    // { name: 'twitter:site', content: '@<Twitter username>' },
    { name: 'twitter:title', content: `${title} | Redflag AI | The Industry Standard For Content And Brand Protection` },
    { name: 'twitter:description', content: description },
    // { name: 'twitter:creator', content: '@<Twitter username>' },
    { name: 'twitter:image:src', content: customImage || seoImage },
    { name: 'msapplication-TileImage', content: seoImage },
    { property: 'og:title', name: 'title', content: `${title} | Redflag AI | The Industry Standard For Content And Brand Protection` },
    { property: 'og:url', name: 'url', content: seoUrl(path) },
    { property: 'og:image', name: 'image', content: customImage || seoImage },
    { property: 'og:description', name: 'description', content: description },
    { property: 'og:site_name', name: 'site name', content: 'Redflag AI | The Industry Standard For Content And Brand Protection' },
    { property: 'og:locale', name: 'locale', content: 'en_EN' },
    // { name: 'fb:app_id', content: '<FB App ID>' },
  ];

  if (published) metaTags.push({ property: 'article:published_time', name: 'article:published_time', content: published });
  if (updated) metaTags.push({ property: 'article:modified_time', name: 'article:modified_time', content: updated });
  if (category) metaTags.push({ property: 'article:section', name: 'article:section', content: category });
  if (tags) metaTags.push({ property: 'article:tag', name: 'article:tag', content: tags });
  if (contentType) metaTags.push({ property: 'og:type', name: 'type', content: contentType });

  return metaTags;
};

const renderSeo = (isEmpty, faviconStatus, children, render) => {
  if (isEmpty) {
    let temp = documentHead;
    if ((isSafari || isEdge) && faviconStatus === 'loading') {
      temp = `... ${documentHead}`;
    } else if (faviconStatus === 'ready') {
      temp = `! ${documentHead}`;
    }

    return (
      <Helmet>
        <title>{temp}</title>
        <meta name="description" content="Redflag AI | The Industry Standard For Content And Brand Protection" />
        <meta name="robots" content="noindex, nofollow" />
        {children}
      </Helmet>
    );
  }

  return render();
};

const Seo = ({
  faviconStatus,
  title = null,
  description = 'Redflag AI | The Industry Standard For Content And Brand Protection',
  path = null,
  customImage = null,
  contentType = null,
  published = null,
  updated = null,
  category = null,
  tags = null,
  children = null,
}) => {
  let temp = title;
  if ((isSafari || isEdge) && faviconStatus === 'loading') {
    temp = `... ${title}`;
  } else if (faviconStatus === 'ready') {
    temp = `! ${title}`;
  }

  return (renderSeo(!title, faviconStatus, children, () => (
    <Helmet>
      <title>{temp}</title>
      <link rel="canonical" href={seoUrl(path)} />
      <meta name="robots" content="follow, index" />
      {getMetaTags({
        title,
        description,
        contentType,
        path,
        customImage,
        published,
        updated,
        category,
        tags,
      }).map((props) => <meta key={uniqid()} {...props} />)}
      {children}
    </Helmet>
  )));
};

function mapStateToProps(state) {
  return { faviconStatus: state.favicon.faviconStatus };
}

Seo.propTypes = {
  schema: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  customImage: PropTypes.string,
  contentType: PropTypes.string,
  published: PropTypes.string,
  updated: PropTypes.string,
  category: PropTypes.string,
  tags: PropTypes.string,
  children: PropTypes.node,
  faviconStatus: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(Seo);
