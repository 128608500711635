import { APPLOADER_CLEAR, APPLOADER_READY } from '../actions/actionTypes';

const initialState = {
  loading: false,
};

const appLoader = (state = initialState, action) => {
  switch (action.type) {
    case APPLOADER_READY:
      return { ...state, loading: true };

    case APPLOADER_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default appLoader;
