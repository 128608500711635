import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import styled from 'styled-components';

import { ReactTags } from 'react-tag-autocomplete';

import { Box } from 'grommet';

import { TagButton } from './ProductFormComponent';

const InputContainer = styled(Box)`
  > .react-tags {
    width: unset;
  }

  ${({ disabled }) => disabled && `
    &, * { pointer-events: none; }
  `}

  ${({ inputAdded }) => inputAdded && `
    .react-tags__combobox { visibility: hidden; }
  `}
`;

const MultipleTagInputs = ({
  small,
  maxInputs = null,
  handleFormValues,
  ...rest
}) => {
  const [tags, setTags] = React.useState([]);

  const handleDelete = (index) => {
    const filteredTags = tags.filter((_, i) => i !== index);
    handleFormValues(filteredTags.map(({ label }) => label));
    setTags(filteredTags);
  };

  const handleAddition = (tag) => {
    if (!maxInputs || tags.length < maxInputs) {
      tag.value = uniqid();

      const addedTags = [...tags, tag];
      handleFormValues(addedTags.map(({ label }) => label));
      setTags(addedTags);
    }
  };

  return (
    <InputContainer className="authTagsContainer" width="100%" {...rest}>
      <ReactTags
        allowNew
        allowBackspace={tags.length > 0}
        selected={tags}
        renderTag={TagButton}
        suggestions={[]}
        suggestionsFilter={() => null}
        onDelete={(i) => handleDelete(i)}
        onAdd={(tag) => handleAddition(tag)}
        placeholderText=""
        onShouldExpand={(value) => value.length >= 1}
        delimiterKeys={['Tab', 'Enter']}
      />
    </InputContainer>
  );
};

MultipleTagInputs.propTypes = {
  maxInputs: PropTypes.number,
  small: PropTypes.bool.isRequired,
  handleFormValues: PropTypes.func.isRequired,
};

export default MultipleTagInputs;
