import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Form, Box } from 'grommet';

import SubmissionLayout, {
  StyledSubmissionInput, StyledSubmissionField, StyledSubmissionAnchor,
} from '@Components/Layout/Submission';
import { AppButton } from '@Components/Control';

import { authLogInRequest } from '@Actions';

import { paths } from '@Components/configs';

const LoginPage = ({
  small,
  logIn,
  config: {
    bgColor,
    bgImage,
    textColor,
    highlight,
  },
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = e.value;
    logIn(email, password);
  };

  return (
    <SubmissionLayout
      seoConfig={null}
      title="Welcome back"
      bgColor={bgColor}
      bgImage={bgImage}
      textColor={textColor}
      small={small}
    >
      <Form onSubmit={(e) => handleSubmit(e)}>
        <StyledSubmissionField required name="email" htmlFor="email" type="email">
          <StyledSubmissionInput
            plain
            name="email"
            type="email"
            placeholder="Email"
            highlight={highlight}
          />
        </StyledSubmissionField>
        <StyledSubmissionField required name="email" htmlFor="email" type="email">
          <StyledSubmissionInput
            plain
            name="password"
            type="password"
            placeholder="Password"
            highlight={highlight}
          />
        </StyledSubmissionField>
        <AppButton
          overrideHover
          type="submit"
          level="authSubmit"
          bgColor={highlight}
          color="white"
          label="Sign In"
          alignSelf="center"
        />
        <Box pad={{ top: '1rem' }}>
          <StyledSubmissionAnchor
            label="Forgot password"
            path={paths.forgotPassword}
            color={textColor}
          />
        </Box>
      </Form>
    </SubmissionLayout>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logIn: authLogInRequest }, dispatch);
}

LoginPage.propTypes = {
  small: PropTypes.bool.isRequired,
  logIn: PropTypes.func.isRequired,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(LoginPage);
