import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  Box, Text, Image,
} from 'grommet';

import { AppButton } from '@Components/Control';


const UnderConstruction = ({
  bgColor = 'white',
  textColor = '#3C3C3C',
  buttonHighlight,
  imageUrl,
  message,
  header = null,
}) => {
  const navigate = useNavigate();

  return (
    <Box flex align="center" justify="center">
      <Box
        background={bgColor}
        direction="column"
        gap="1rem"
        align="center"
        justify="center"
        round="0.625rem"
        pad="2rem"
        width="22rem"
      >
        <Box flex={false} width="100%" height="auto">
          <Image
            src={imageUrl}
            alt="Page under maintanence"
            fit="contain"
            width="100%"
            height="auto"
          />
        </Box>
        <Box direction="column" gap="0.65rem">
          <Text color={textColor} size="1.5rem" weight={600} textAlign="center">{header ?? 'Under Construction'}</Text>
          <Text color={textColor} size="1rem" textAlign="center">{message}</Text>
        </Box>
        <Box width="100%" align="center" pad={{ top: '1rem' }}>
          <AppButton
            overrideHover
            width="7.25rem"
            height="3rem"
            onClick={() => navigate(-1)}
            level="dynamicLarge"
            color="white"
            bgColor={buttonHighlight}
            fontWeight={600}
            label="Go back"
          />
        </Box>
      </Box>
    </Box>
  );
};

UnderConstruction.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonHighlight: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  header: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default UnderConstruction;
