import styled from 'styled-components';

import {
  Box, Button, DataTable, Text, Anchor, Table, TableRow,
} from 'grommet';
import { AppButton } from '@Components/Control';

const StyledPagedTable = styled(DataTable)`
  th {
    border-bottom: 1px solid #E5E9EB;
    background-color: white;
  }
`;

const StyledPagedTableRowLabel = styled(Text)`
  * { font-weight: inherit; }
`;

const StyledPagedTableUrl = styled(Anchor)`
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow:hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;

  &:hover { text-decoration: underline; }

  @media (max-width: 48em) {
    -webkit-line-clamp: 3;
  }
`;

const StyledExtraUrlContainer = styled(Box)`
  span {
    word-break: keep-all;
    text-wrap: nowrap;
    font-size: 0.8rem;
    font-weight: 600;
    color: #3C3C3C;
  }

  a {
    font-size: 0.8rem;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow:hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;

    &:hover { text-decoration: underline; }

    @media (max-width: 768px) {
      -webkit-line-clamp: 2;
    }
  }

  @media (max-width: 768px) {
    > div {
      flex-direction: column;
      align-items: start;
    }

    span, a { font-size: 0.95rem; }
  }
`;

const StyledDetailTable = styled(Table)`
  th { border-bottom: 1px solid #E5E9EB; }
`;

const StyledDetailTableRow = styled(TableRow)`
  background-color: ${({ rowColor }) => rowColor};
  border-bottom: 1px solid #E5E9EB;

  @media (max-width: 768px) {
    border-bottom: ${({ isLast }) => (isLast ? '1px solid #E5E9EB' : 'none')};
  }
`;

const StyledDropMenu = styled(Button)`
  padding: 0.5rem 0.45rem;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 6px;
  color: ${({ textColor }) => textColor};

  &:hover {
    color: ${({ hoverTextColor }) => hoverTextColor};
  }

  @media (max-width: 768px) {
    padding: 0.75rem 0.45rem;
  }
`;

const StyledDetailDropMenu = styled(Box)`
  padding: 0.25rem 0.45rem;
  font-size: 0.9rem;
  cursor: default;
  letter-spacing: normal;
  overflow: auto;
  white-space: pre-line;
  color: #252C32;
  font-weight: 600;
`;

const StyledAppButton = styled(AppButton)`
  text-align: start;
  polyline { stroke-width: 3px; }
  background-color: #F2F2F2;
  border-radius: 6px;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;

  ${({ noPadLeft }) => noPadLeft && `
    padding-left: 0px;
  `};

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
  }
`;

const StyledExtraUrlButton = styled(Box)`
  text-align: start;
  background-color: #F2F2F2;
  border-radius: 6px;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #585858;

  @media (max-width: 768px) {
    padding: 0.35rem 0.6rem;
    font-size: 0.8rem;
  }
`;

export {
  StyledPagedTable,
  StyledPagedTableRowLabel,
  StyledPagedTableUrl,
  StyledDetailTable,
  StyledDetailTableRow,
  StyledDropMenu,
  StyledDetailDropMenu,
  StyledAppButton,
  StyledExtraUrlContainer,
  StyledExtraUrlButton,
};
