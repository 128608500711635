import React from 'react';
import styled from 'styled-components';

import {
  Box, Heading, Button,
} from 'grommet';
import { AppAnchor } from '@Components/Control';

const StyledFooterAnchor = styled((props) => <AppAnchor {...props} />)`
  && {
    font-weight: 600;
    font-size: ${({ isSmall }) => (isSmall ? '1.1rem' : '0.85rem')};
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

    &:hover {
      color: ${({ color }) => ((color === '#7F7F7F') ? '#788af7' : 'white')};
      opacity: 1;
      transform: scale(1.0125);
    }
  }
`;

const StyledFooterAnchorWrapper = styled(Box)`
  &:hover {
    ${StyledFooterAnchor}:not(:hover) {
      opacity: ${({ overrideHover }) => (overrideHover ? '0.6' : '1')};
    }
  }
`;

const StyledFooterSection = styled(Box)`
  margin-right: 5rem;

  @media (max-width: 768px) {
    width: 12.5rem;
    margin-right: 1.5rem;
  }
`;

const StyledFooterHeading = styled(Heading)`
  font-weight: 600;
  font-size: 1.25rem;
  word-break: keep-all;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const StyledModalButton = styled(Button)`
  -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  &:hover {
    color: ${({ highlight }) => highlight};
  }
`;

const StyledMailtoAnchor = styled(AppAnchor)`
  -webkit-transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  &:hover {
    color: ${({ highlight }) => highlight};
  }
`;

export {
  StyledFooterAnchor,
  StyledFooterAnchorWrapper,
  StyledFooterSection,
  StyledFooterHeading,
  StyledModalButton,
  StyledMailtoAnchor,
};
