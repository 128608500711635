import {
  FAVICON_CLEAR,
  FAVICON_LOADING,
  FAVICON_READY,
} from '../actions/actionTypes';

const initialState = {
  faviconStatus: 'default',
};

const favicon = (state = initialState, action) => {
  switch (action.type) {
    case FAVICON_CLEAR:
      return { ...state, faviconStatus: 'default' };

    case FAVICON_LOADING:
      return { ...state, faviconStatus: 'loading' };

    case FAVICON_READY:
      if (state.faviconStatus === 'loading') {
        return { ...state, faviconStatus: 'ready' };
      }
      // falls through

    default:
      return state;
  }
};

export default favicon;
