import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';

import { Box } from 'grommet';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import {
  refreshEmbedAccessDataRequest,
  clearEmbedAccessDataRequest,
} from '@Actions';
import { secondsSinceEpoch } from '@Helpers/util';

import { StyledFrameContainer } from './StyledPiracyOverviewPage';
import { NoDataPlaceholder } from '../../../Partial/Product/SharedComponents';


const PiracyOverviewPage = ({
  small,
  loading,
  mixpanel,
  location,
  authPagesConfig = null,
  embedConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  refreshAccessData,
  clearAccessData,
  companyId,
  accessToken = null,
  accessTokenExpiration = null,
  isReport = false,
}) => {
  const [embedToken, setEmbedToken] = React.useState(null);
  const [frameLoaded, setFrameLoaded] = React.useState(false);
  const [frameWidth, setFrameWidth] = React.useState(0);
  const { id } = useParams();

  let embedId = null;
  let applyCompanyFilter = null;
  let embedTitle = null;

  if (!isReport) {
    embedId = embedConfig.embedId;
    applyCompanyFilter = embedConfig.applyCompanyFilter;
  } else {
    const useReports = reportsConfig.find(
      ({ metadata }) => metadata.config.companyId === companyId,
    );
    const currentEmbed = useReports.metadata.config.customReports.find(
      ({ embedId: currentId }) => currentId === id,
    );

    embedId = currentEmbed.embedId;
    applyCompanyFilter = currentEmbed.filterById;
    embedTitle = currentEmbed.embedTitle;
  }

  const baseServerUrl = () => {
    switch (process.env.NODE_ENV) {
      case 'development':
        return `http://localhost:${process.env.PORT}/`;
      case 'staging':
      case 'production':
        return '/';
      default:
        return '/';
    }
  };

  const fetchServerEmbedToken = async (useToken) => {
    const options = { method: 'GET' };
    let embedParams = `token=${useToken}&embedId=${embedId}`;

    if (applyCompanyFilter) {
      embedParams += `&companyId=${companyId}`;
    }

    const response = await fetch(`${baseServerUrl()}api/embedToken?${embedParams}`, options);
    const responseContent = await response.text();
    setEmbedToken(responseContent);
  };

  const handleResize = () => {
    const containerWidth = document.querySelector('.iframe-container')?.offsetWidth;
    setFrameWidth(containerWidth);
  };

  React.useEffect(() => {
    if (embedToken) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    if (!accessToken || accessTokenExpiration - 60 > secondsSinceEpoch()) {
      refreshAccessData();
    }

    return () => {
      clearAccessData();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (accessToken) {
      fetchServerEmbedToken(accessToken);
    }
  }, [accessToken]);

  const { primaryText, buttonHighlight } = authPagesConfig;

  const handleFrameLoad = (event) => {
    const iframe = event.target;

    if (iframe?.contentDocument?.getElementById('embed-form')) {
      const form = iframe.contentDocument.getElementById('embed-form');
      form.submit();
    } else {
      setFrameLoaded(true);
    }
  };

  const pageTitle = isReport ? `Custom Report - ${embedTitle}` : 'Piracy Overview';

  return (
    <AuthNavWrapper
      smallBanner
      hideFooter
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: pageTitle,
        subTitle: 'Content protection hub',
        textColor: 'white',
      }}
    >
      <Seo />
      <Box flex background="#F1F2FC" pad={{ vertical: '1rem', horizontal: small ? '0.5rem' : '6.5vw' }} height="100%">
        {(loading || !embedToken || !frameLoaded) && (
          <NoDataPlaceholder
            noRound
            label="Unable to load overview"
            textColor={primaryText}
            backgroundColor="white"
            loading={loading || !embedToken || !frameLoaded}
            loaderColor={buttonHighlight}
          />
        )}
        {embedToken && (
          <StyledFrameContainer
            flex
            className="iframe-container"
            align="center"
            justify="center"
            style={{ visibility: frameLoaded ? 'visible' : 'hidden' }}
          >
            <iframe
              id="domoEmbed"
              title="Domo Piracy Overview"
              width={frameWidth}
              allow="fullscreen"
              srcDoc={`
                <!DOCTYPE html>
                <html>
                  <body>
                    <form
                      id="embed-form"
                      action="${process.env.DOMO_EMBED_BASE_URL}${embedId}"
                      method="post"
                    >
                      <input type="hidden" name="embedToken" value=${embedToken} />
                    </form>
                  </body>
                </html>
              `}
              onLoad={handleFrameLoad}
            />
          </StyledFrameContainer>
        )}
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    accessToken: state.productEmbed.embedAccessToken,
    accessTokenExpiration: state.productEmbed.embedAccessTokenExpiration,
    loading: state.fetchLoader.dataLoading,
    companyId: state.auth.companyId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    refreshAccessData: refreshEmbedAccessDataRequest,
    clearAccessData: clearEmbedAccessDataRequest,
  }, dispatch);
}

PiracyOverviewPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  embedConfig: PropTypes.shape({
    embedId: PropTypes.string.isRequired,
    secondaryEmbedId: PropTypes.string,
    applyCompanyFilter: PropTypes.bool,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedCover: PropTypes.string.isRequired,
          embedId: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          filterById: PropTypes.bool,
          isFavorite: PropTypes.bool,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  })),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  refreshAccessData: PropTypes.func.isRequired,
  clearAccessData: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  accessTokenExpiration: PropTypes.number,
  companyId: PropTypes.number.isRequired,
  isReport: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(PiracyOverviewPage));
