import React from 'react';
import PropTypes from 'prop-types';

import { NavbarLayout, NavbarSmallLayout } from './HeaderComponent';


const Header = ({ small, darkHeader }) => {
  if (small) {
    return (
      <NavbarSmallLayout simple darkBackground={darkHeader} />
    );
  }

  return (
    <NavbarLayout simple darkBackground={darkHeader} />
  );
};

Header.propTypes = {
  small: PropTypes.bool.isRequired,
  darkHeader: PropTypes.bool.isRequired,
};

export default Header;
