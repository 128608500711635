import styled from 'styled-components';

import {
  Box, Heading, Button,
} from 'grommet';
import { ReactSVG } from 'react-svg';

const StyledCyclopsContainer = styled(Box)`
  border-radius: 10px;
  padding: 1rem;
`;

const StyledCyclopsHeading = styled(Heading)`
  font-weight: 600;
  line-height: 30px;
  margin-block-start: 1.5rem;
  margin-block-end: 0;
  font-size: 1.5rem;
`;

const StyledHr = styled.hr`
  color: ${({ color }) => color};
  width: 100%;
  margin-inline-end: 0;
  margin-inline-start: 0;
`;

const StyledSelectionContainer = styled(Button)`
  border-radius: 10px;
  position: relative;

  .checkMark {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  img { border: 1px solid transparent; }
  
  &:hover img {
    border: ${({ highlightColor }) => `1px solid ${highlightColor}`};
  }
`;

const StyledSVG = styled(ReactSVG)`
  svg {
    height: ${({ height }) => height};
    width:${({ width }) => width};
    fill: ${({ $fillColor }) => $fillColor};

    path {
      fill: ${({ $fillColor }) => $fillColor};
    }
  }
`;

const StyledEventButton = styled(Button)`
  display: flex;
  flex-basis: 30%;

  .imageContainer {
    position: relative;
  }

  .liveBadge {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export {
  StyledCyclopsContainer,
  StyledCyclopsHeading,
  StyledHr,
  StyledSelectionContainer,
  StyledSVG,
  StyledEventButton,
};
