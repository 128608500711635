let abortController = new AbortController();

// Public components actions
export function resetAbortController() {
  abortController.abort();
  abortController = new AbortController();
}

// Private: only to be used within actions
export function getAbortControllerSignal() {
  return abortController.signal;
}
