import React from 'react';
import PropTypes from 'prop-types';

import { DropButton as Drop } from 'grommet';
import {
  Up, FormUp, Down, FormDown,
} from 'grommet-icons';


const DropButton = ({
  children,
  onClose = () => null,
  onOpen = () => null,
  dropContent = null,
  withCloseDropContent = null,
  overridePosition = null,
  formIcons = false,
  ...rest
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleOpen = () => {
    if (onOpen) onOpen();
    setIsVisible(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    setIsVisible(false);
  };

  const upIcon = formIcons ? FormUp : Up;
  const downIcon = formIcons ? FormDown : Down;

  return (
    <Drop
      as="div"
      open={isVisible}
      onClose={rest.disabled ? null : () => handleClose()}
      onOpen={rest.disabled ? null : () => handleOpen()}
      dropAlign={overridePosition || { top: 'bottom', right: 'right' }}
      dropContent={withCloseDropContent ? withCloseDropContent(() => handleClose()) : dropContent}
      {...rest}
    >
      {children(isVisible ? upIcon : downIcon)}
    </Drop>
  );
};

DropButton.propTypes = {
  children: PropTypes.func.isRequired,
  dropContent: PropTypes.node,
  withCloseDropContent: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  disabled: PropTypes.bool,
  overridePosition: PropTypes.shape({
    top: PropTypes.string,
    right: PropTypes.string,
  }),
  formIcons: PropTypes.bool,
};

export default DropButton;
