import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import _ from 'lodash';

import {
  Box, Text, Layer, Button,
} from 'grommet';
import { FormView, FormClose } from 'grommet-icons';

import { AppButton } from '@Components/Control';
import {
  StyledRaisedContainer,
  StyledModalActivateButton,
  StyledModalSelectButton,
  StyledFormCloseContainer,
} from './StyledProductFormComponent';


const ModalSelect = ({
  small,
  handleFormValues,
  value = null,
  options = null,
  description = null,
  fieldTitle,
  authPagesConfig: {
    primaryText,
    secondaryText,
    focusHighlight,
    hintText,
    buttonHighlight,
    selectButtonBg,
  },
  inputConfig = null,
  searchTypeKey,
  ...rest
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedValues, setSelectedValues] = React.useState([]);
  let selectableValues = options;

  const handleSelection = (uid) => {
    const prevSelected = [...selectedValues];
    if (prevSelected.includes(uid)) {
      _.pull(prevSelected, uid);
    } else {
      prevSelected.push(uid);
    }

    setSelectedValues(prevSelected);
  };

  let displayValues = value && value.join(', ');

  if (value && value.length > 4) {
    displayValues = `${value.slice(0, 4).join(', ')} + ${value.length - 4} more`;
  }

  if (inputConfig && inputConfig.supportedCategoryValues) {
    selectableValues = inputConfig.supportedCategoryValues[searchTypeKey];
  }

  const allowSelection = selectedValues?.length >= 1 || value?.length >= 1;

  return (
    <Box>
      <StyledModalActivateButton
        plain
        lessPad
        textSize="1rem"
        onClick={() => setShowModal(true)}
        modalOpen={showModal}
        highlight={focusHighlight}
        label={(
          <Box direction="row" justify="between" align="center">
            <Text size="0.8rem" color={(value && value.length) ? primaryText : hintText}>
              {displayValues || 'Select options'}
            </Text>
            <FormView color={secondaryText} size="1.3rem" />
          </Box>
        )}
        {...rest}
      />
      {showModal && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => {
            if (allowSelection) handleFormValues(selectedValues);
            setShowModal(false);
          }}
          onClickOutside={() => {
            if (allowSelection) handleFormValues(selectedValues);
            setShowModal(false);
          }}
        >
          <StyledRaisedContainer
            gap="0.5rem"
            align="center"
            pad="1.5rem"
            width={{ min: small ? '96vw' : '50rem' }}
          >
            <Box direction="row" width="100%" justify="between">
              <Text weight={600} size="1rem" color={primaryText}>
                {fieldTitle}
              </Text>
              <Button
                plain
                onClick={() => {
                  if (allowSelection) handleFormValues(selectedValues);
                  setShowModal(false);
                }}
              >
                <StyledFormCloseContainer highlight={buttonHighlight} direction="row" gap="0.25rem" align="center">
                  <Text weight={600} size="0.9rem" color={primaryText}>
                    Close
                  </Text>
                  <FormClose color={primaryText} size="1.2rem" />
                </StyledFormCloseContainer>
              </Button>
            </Box>
            {description && (
              <Box width="100%">
                <Text size={small ? '0.85rem' : '0.75rem'} color={hintText}>
                  {description}
                </Text>
              </Box>
            )}
            <Box wrap direction="row">
              {selectableValues && selectableValues.map((dt) => (
                <Box key={uniqid()} pad={{ right: '1rem', vertical: '0.5rem' }}>
                  <StyledModalSelectButton
                    plain
                    selected={selectedValues.includes(dt)}
                    textSize="1rem"
                    onClick={() => handleSelection(dt)}
                    primaryText={primaryText}
                    buttonHighlight={buttonHighlight}
                    buttonBg={selectButtonBg}
                    label={dt}
                  />
                </Box>
              ))}
            </Box>
            <Box
              width="100%"
              pad={{ top: '1rem' }}
              align="center"
              border={{
                color: '#D0D0D0',
                size: '1px',
                style: 'solid',
                side: 'top',
              }}
            >
              <AppButton
                overrideHover
                width="7.25rem"
                height="3rem"
                onClick={() => {
                  if (allowSelection) handleFormValues(selectedValues);
                  setShowModal(false);
                }}
                level="dynamicLarge"
                color="white"
                bgColor={buttonHighlight}
                fontWeight={600}
                label="Finished"
              />
            </Box>
          </StyledRaisedContainer>
        </Layer>
      )}
    </Box>
  );
};

ModalSelect.propTypes = {
  small: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
  }).isRequired,
  inputConfig: PropTypes.shape({
    supportedCategoryValues: PropTypes.objectOf(PropTypes.any),
  }),
  searchTypeKey: PropTypes.string.isRequired,
  handleFormValues: PropTypes.func.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
  description: PropTypes.string,
};

export default ModalSelect;
