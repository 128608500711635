import React from 'react';
import PropTypes from 'prop-types';
import {
  format, subDays, parseISO,
} from 'date-fns';
import { UTCDate } from '@date-fns/utc';

import {
  Box, Layer, Image, Text,
} from 'grommet';

import { SingleDateCalendar } from '@Components/Control/Calendar';
import {
  StyledModalActivateButton,
} from './StyledProductFormComponent';


const CalendarInput = ({
  small,
  handleFormValues,
  value = undefined,
  searchTypeKey = null,
  minStartDate = null,
  today,
  authPagesConfig,
  inputConfig = null,
  ...rest
}) => {
  const [showCalendar, setShowCalendar] = React.useState(false);
  const dateFormat = value && value.includes('T') ? "M/d/yyyy HH':'mm" : 'M/d/yyyy';
  const yesterday = format(subDays(new UTCDate(today), 1), 'yyyy-MM-dd');
  const withTimeSelect = inputConfig && inputConfig.format && searchTypeKey
    && inputConfig.format[searchTypeKey]
    && (inputConfig.format[searchTypeKey].optionalTime === true);

  const {
    primaryText,
    hintText,
    focusHighlight,
    icons: {
      calendarInputIcon,
    },
  } = authPagesConfig;

  return (
    <Box>
      <StyledModalActivateButton
        plain
        modalOpen={showCalendar}
        highlight={focusHighlight}
        textSize="1rem"
        onClick={() => setShowCalendar(true)}
        label={(
          <Box wrap={false} direction="row" justify="between" align="center" pad={{ right: '0.15rem' }}>
            <Text size="0.8rem" color={value ? primaryText : hintText}>
              {value ? format(parseISO(value), dateFormat) : 'Select a date'}
            </Text>
            <Image src={calendarInputIcon} width="18px" height="18px" />
          </Box>
        )}
      />
      {showCalendar && (
        <Layer
          responsive={false}
          animate={false}
          onEsc={() => setShowCalendar(false)}
          onClickOutside={() => setShowCalendar(false)}
        >
          <SingleDateCalendar
            className="authCalendar"
            round="0.35rem"
            pad={{ top: '2rem', bottom: '0', horizontal: '0.5rem' }}
            background="white"
            setDate={(val) => handleFormValues(val)}
            minStartDate={minStartDate || yesterday}
            date={value}
            calendarFormat={withTimeSelect ? 'datetime' : null}
            useAltTimeSelect={withTimeSelect}
            authPagesConfig={authPagesConfig}
            {...rest}
          />
        </Layer>
      )}
    </Box>
  );
};

CalendarInput.propTypes = {
  small: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    icons: PropTypes.shape({
      calendarInputIcon: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  inputConfig: PropTypes.shape({
    format: PropTypes.shape({
      sports: PropTypes.shape({
        optionalTime: PropTypes.bool,
      }),
    }),
  }),
  searchTypeKey: PropTypes.string,
  handleFormValues: PropTypes.func.isRequired,
  minStartDate: PropTypes.string,
  today: PropTypes.instanceOf(Date).isRequired,
  value: PropTypes.string,
};

export default CalendarInput;
