import {
  REDIRECT_REQUESTED,
  REDIRECT_COMPLETED,
} from '../actions/actionTypes';

const initialState = {
  isRequired: false,
  path: null,
  push: false,
};

const redirect = (state = initialState, action) => {
  switch (action.type) {
    case REDIRECT_REQUESTED:
      return {
        ...state,
        isRequired: true,
        path: action.payload.path || { pathname: action.payload.pathname },
        push: action.payload.push,
      };

    case REDIRECT_COMPLETED:
      return {
        ...state,
        isRequired: false,
        path: null,
        push: false,
      };

    default:
      return state;
  }
};

export default redirect;
