import styled from 'styled-components';

import { Box } from 'grommet';

const StyledAccountInfoContainer = styled(Box)`
  background: white;
  font-size: 1rem;
  border: solid 1px #D0D0D0;
  color: var(--primary-text);
  padding: 0.65rem 1rem;
  border-radius: 5px;

  &:hover, &:focus, &:focus-within {
    border: ${({ focusHighlight }) => `solid 1px ${focusHighlight}`};
  }
`;

export default StyledAccountInfoContainer;
