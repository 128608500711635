import {
  NOTICE_LOADED,
  NOTICE_CLEAR,
} from '../actions/actionTypes';

const initialState = {
  message: null,
  type: null,
};

const notice = (state = initialState, action) => {
  switch (action.type) {
    case NOTICE_LOADED:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type || 'warning',
      };

    case NOTICE_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default notice;
