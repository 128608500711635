import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text, Image,
} from 'grommet';
import { CaretUpFill, CaretDownFill } from 'grommet-icons';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';

import {
  StyledCyclopsContainer, StyledCyclopsHeading,
} from './StyledCyclopsComponents';


const CyclopsAnalyticsPage = ({
  small,
  mixpanel,
  location,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyCyclopsData = null,
}) => {
  const {
    darkModeColors: {
      primaryDarkBg,
      containerBg,
      primaryText,
      secondaryText,
      incrementText,
      decrementText,
    },
  } = authPagesConfig;
  let analyticsData = null;
  let dataCards = null;
  let visualizations = null;

  if (companyCyclopsData) {
    analyticsData = companyCyclopsData.metadata.config.analyticsData;
    dataCards = analyticsData.dataCards;
    visualizations = analyticsData.visualizations;
  }

  const renderValueText = (value, valueType = null) => {
    if (valueType && valueType === 'int') {
      return (
        <Text size="1.75rem" weight={600} color={primaryText}>
          {value.toLocaleString()}
        </Text>
      );
    }

    return (
      <Text size="1.75rem" weight={600} color={primaryText}>
        {value}
      </Text>
    );
  };

  const renderValueChangeText = (valueChange) => {
    if (valueChange.includes('-')) {
      return (
        <Box direction="row" pad={{ bottom: '0.15rem' }}>
          <CaretDownFill size="1rem" color={decrementText} />
          <Text size="0.75rem" weight={400} color={decrementText}>
            {valueChange}
          </Text>
        </Box>
      );
    }

    return (
      <Box direction="row" pad={{ bottom: '0.15rem' }}>
        <CaretUpFill size="1rem" color={incrementText} />
        <Text size="0.75rem" weight={400} color={incrementText}>
          {valueChange}
        </Text>
      </Box>
    );
  };

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box flex background={primaryDarkBg} pad={small ? '2rem' : '3rem'} direction="column">
        <StyledCyclopsHeading level={2} color={primaryText}>
          Livestream Analytics
        </StyledCyclopsHeading>
        <Box wrap direction="row" justify="between" pad={{ top: '1rem' }}>
          {dataCards?.map(({
            title, value, type, valueChange,
          }) => (
            <Box basis={small ? 'full' : '1/3'} pad={{ vertical: '1rem', horizontal: small ? '0' : '1rem' }} margin={{ left: small ? '0' : '-1rem' }} key={uniqid()}>
              <StyledCyclopsContainer
                background={containerBg}
                direction="column"
                gap="1rem"
                width="100%"
              >
                <Text size="1rem" weight={500} color={secondaryText}>{title}</Text>
                <Box direction="row" gap="0.5rem" align="end">
                  <Text size="1.5rem" weight={600} color={primaryText}>{renderValueText(value, type)}</Text>
                  {renderValueChangeText(valueChange)}
                </Box>
              </StyledCyclopsContainer>
            </Box>
          ))}
        </Box>
        <Box wrap direction="row" justify="between">
          {visualizations?.map(({
            title, value, type, placeholder,
          }) => (
            <Box basis={small ? 'full' : '1/2'} pad={{ vertical: '1rem', horizontal: small ? '0' : '1rem' }} margin={{ left: small ? '0' : '-1rem' }} key={uniqid()}>
              <StyledCyclopsContainer
                flex
                background={containerBg}
                direction="column"
                gap="1rem"
                width="100%"
              >
                <Text size="1rem" weight={500} color={secondaryText}>{title}</Text>
                {value && (
                  <Box direction="row" gap="0.5rem" align="end">
                    <Text size="1.5rem" weight={600} color={primaryText}>{renderValueText(value, type)}</Text>
                  </Box>
                )}
                <Image src={placeholder} fit="contain" />
              </StyledCyclopsContainer>
            </Box>
          ))}
        </Box>
      </Box>
    </AuthNavWrapper>
  );
};

CyclopsAnalyticsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      incrementText: PropTypes.string.isRequired,
      decrementText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyCyclopsData: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        analyticsData: PropTypes.shape({
          dataCards: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]).isRequired,
            type: PropTypes.string.isRequired,
            valueChange: PropTypes.string.isRequired,
          }).isRequired).isRequired,
          visualizations: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]),
            type: PropTypes.string,
            placeholder: PropTypes.string.isRequired,
            vizType: PropTypes.string.isRequired,
          }).isRequired).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

export default withProductAuth(CyclopsAnalyticsPage);
