import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box } from 'grommet';

import { Seo, FullHeightBox } from '@Components/Control';
import { ListSearchesTablePartial } from '@Components/Partial/Product/ListSearches';
import { withProductAuth } from '@Components/Layout';
import { AuthBanner } from '@Components/Navigation';
import { paths } from '@Components/configs';
import {
  initiateProductListSearchesFlexDataRequest,
  clearProductListSearchesFlexDataRequest,
} from '@Actions';


const ListSearchesPage = ({
  small,
  loading,
  fetchFlexibleData,
  clearFlexibleData,
  flexibleSearchesData = null,
  authPagesConfig = null,
}) => {
  const [query, setQuery] = React.useState({
    paging: {
      to: 1,
      size: 10,
    },
  });
  const dashboardPath = (searchId) => paths.productFlexibleSearchResults.replace(':id', searchId);

  React.useEffect(() => {
    fetchFlexibleData({ ...query });

    return () => {
      clearFlexibleData();
    };
  }, [query]);

  const handleQuery = (parentKey, updateKey, value) => {
    const prevQuery = { ...query };

    if (parentKey && !updateKey) {
      prevQuery[parentKey] = value;
    } else {
      prevQuery[parentKey][updateKey] = value;
    }

    setQuery(prevQuery);
  };

  const headerMap = [
    {
      display: 'Title',
      uid: 'title',
      size: '27%',
    },
    {
      display: 'Infringements Found',
      uid: 'infringements',
      size: '20%',
    },
    {
      display: 'Platform',
      uid: 'platforms',
      size: '28%',
    },
    {
      display: 'Status',
      uid: 'status',
      size: '25%',
    },
  ];
  const resultsPerPageOptions = [10, 15, 20, 25];
  const padding = small ? '1.5rem' : '2rem';

  return (
    <>
      <Seo />
      <FullHeightBox background="#F1F2FC" pad={{ top: small ? '5.2rem' : '3rem' }}>
        <AuthBanner
          navCollapsed
          adjustLeftPad
          small={small}
          image={authPagesConfig.bannerBg}
          textColor="white"
          title="All searches"
          subTitle="Blacklight"
          bannerChildren={null}
        />
        <Box flex pad={{ vertical: padding }}>
          <ListSearchesTablePartial
            flexibleData
            small={small}
            listSearchesData={flexibleSearchesData}
            authPagesConfig={authPagesConfig}
            handleQuery={(parentKey, updateKey, value) => handleQuery(parentKey, updateKey, value)}
            resultsPerPageOptions={resultsPerPageOptions}
            dashboardPath={(searchId) => dashboardPath(searchId)}
            searchQuery={query}
            pageTitle="All searches"
            headerMap={headerMap}
            loading={loading}
          />
        </Box>
      </FullHeightBox>
    </>
  );
};

function mapStateToProps(state) {
  return {
    flexibleSearchesData: state.productList.productListSearchesFlexData,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFlexibleData: initiateProductListSearchesFlexDataRequest,
    clearFlexibleData: clearProductListSearchesFlexDataRequest,
  }, dispatch);
}

ListSearchesPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchFlexibleData: PropTypes.func.isRequired,
  clearFlexibleData: PropTypes.func.isRequired,
  flexibleSearchesData: PropTypes.shape({
    query: PropTypes.shape({
      paging: PropTypes.shape({
        to: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        size: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
      }),
    }).isRequired,
    data: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      searches: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        platforms: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        infringements: PropTypes.number.isRequired,
      }).isRequired).isRequired,
    }).isRequired,
  }),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    numberHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    icons: PropTypes.shape({
      seeDetailsIcon: PropTypes.string.isRequired,
      timerClockIcon: PropTypes.string.isRequired,
    }).isRequired,
    statusColorMap: PropTypes.shape({
      running: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      completed: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      paused: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
      scheduled: PropTypes.shape({
        text: PropTypes.string.isRequired,
        background: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(ListSearchesPage));
