import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';

import { Box, Text } from 'grommet';

const StyledCalendarMonth = styled(Text)`
  line-height: 1.29;
  letter-spacing: 0.07rem;
`;

const StyledCalendarText = styled(Text)`
  line-height: 1.25;
  letter-spacing: 0.165rem;
`;

export const GridContainer = ({ children }) => (
  <Box width="1.75rem" height="1.75rem" align="center" justify="center">
    {children}
  </Box>
);

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const calendarStyle = { uppercase: true, margin: 'none', color: '#038BFC' };

export const CalendarMonth = ({ label, ...rest }) => (
  <StyledCalendarMonth size="0.9rem" {...calendarStyle} {...rest}>
    {label}
  </StyledCalendarMonth>
);

CalendarMonth.propTypes = {
  label: PropTypes.string.isRequired,
};

export const CalendarText = ({ label, ...rest }) => (
  <StyledCalendarText size="0.8rem" {...calendarStyle} {...rest}>
    {label}
  </StyledCalendarText>
);

CalendarText.propTypes = {
  label: PropTypes.string.isRequired,
};

export const checkDatePresence = (date, dateFormat = 'yyyy-MM-dd') => (date ? format(date, dateFormat) : undefined);
export const checkDateTimePresence = (datetime, datetimeFormat = "yyyy-MM-dd'T'HH':'mm") => (
  datetime ? format(datetime, datetimeFormat) : undefined
);

export const getTimeList = () => {
  const time = [];
  for (let i = 0; i < 24; i += 1) {
    for (let j = 0; j < 60; j += 15) {
      time.push(`${(i < 10) ? '0' : ''}${i}:${j < 1 ? '0' : ''}${j}`);
    }
  }

  return time;
};
