import styled from 'styled-components';

import { Box } from 'grommet';

const FullHeightBox = styled(Box)`
  ${({ noNav }) => (noNav ? `
    min-height: calc(100vh - 26.07rem);

    @media (max-width: 48em) {
      min-height: calc(100vh - 38.7833rem);
    }

    @media (max-width: 32.8125em) {
      min-height: calc(100vh - 54.25rem);
    }
  ` : 'min-height: 100vh;')}
`;

export default FullHeightBox;
