import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Box, Text, Button,
} from 'grommet';

import { Seo, Spinning } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { paths } from '@Components/configs';
import { localAsset } from '@Helpers/asset';

import { StyledSortButton } from './StyledPiracyOverviewPage';
import { TableWrapper } from '../../../Partial/Product/SharedComponents';
import { StyledSVG } from '../../../Partial/Product/StyledSharedComponents';


const TableControls = ({
  focusHighlight,
  primaryText,
  selectedSortOption,
  handleSort,
  iconPath,
}) => (
  <Box direction="row" gap="0.5rem">
    <StyledSortButton
      plain
      overrideHover
      key="sort"
      textSize="1rem"
      color={primaryText}
      label={selectedSortOption.name}
      highlight={focusHighlight}
      onClick={() => handleSort(selectedSortOption.direction === 'desc' ? 'asc' : 'desc')}
      icon={(
        <StyledSVG
          src={localAsset(iconPath)}
          width="1.1rem"
          height="1.1rem"
          title={selectedSortOption.name}
        />
      )}
    />
  </Box>
);

TableControls.propTypes = {
  focusHighlight: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
  selectedSortOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
};

const CustomReportsListPage = ({
  small,
  mixpanel,
  location,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyId,
}) => {
  const [companyReports, setCompanyReports] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState({ name: 'Sort A-Z', direction: 'desc' });
  const {
    primaryText, buttonHighlight, focusHighlight,
  } = authPagesConfig;

  const useReports = reportsConfig.find(
    ({ metadata }) => metadata.config.companyId === companyId,
  );

  React.useEffect(() => {
    if (!companyReports && useReports?.metadata?.config?.customReports
      && useReports.metadata.config.customReports.length >= 1) {
      const sortedReports = useReports.metadata.config.customReports.sort((a, b) => (
        a.embedTitle.toLowerCase().localeCompare(b.embedTitle.toLowerCase())
      ));
      setCompanyReports(sortedReports);
    }
  }, [useReports]);

  const handleSort = (sortDirection) => {
    const prevReports = [...companyReports];

    if (sortDirection === 'desc') {
      prevReports.sort((a, b) => (
        a.embedTitle.toLowerCase().localeCompare(b.embedTitle.toLowerCase())
      ));
      setSortOrder({ name: 'Sort A-Z', direction: 'desc' });
    } else {
      prevReports.sort((a, b) => (
        b.embedTitle.toLowerCase().localeCompare(a.embedTitle.toLowerCase())
      ));
      setSortOrder({ name: 'Sort Z-A', direction: 'asc' });
    }

    setCompanyReports(prevReports);
  };

  const renderReports = () => {
    if (companyReports) {
      return (
        <Box direction="column">
          {companyReports.map(({ embedId, embedTitle }, i) => (
            <Button plain key={embedId} href={paths.productCustomReport.replace(':id', embedId)}>
              <Box
                wrap={false}
                fill="horizontal"
                direction={small ? 'column' : 'row'}
                border={(i !== companyReports.length - 1) && {
                  color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
                }}
                pad={{ vertical: '1rem' }}
                align={small ? 'start' : 'center'}
                gap={small ? '0.75rem' : '0'}
              >
                <Text size="1.15rem" weight={600} color={primaryText}>{embedTitle}</Text>
              </Box>
            </Button>
          ))}
        </Box>
      );
    }

    return (
      <Box flex justify="center" align="center">
        <Spinning size="large" color={buttonHighlight} />
      </Box>
    );
  };

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Custom Reports',
        subTitle: 'Content protection hub',
        textColor: 'white',
      }}
    >
      <Seo />
      <Box
        flex
        direction="column"
        background="#F1F2FC"
        pad="1.5rem"
        height="100%"
        gap="1.5rem"
      >
        <Box>
          <Text size="1.25rem" color={primaryText} weight={600}>All Reports</Text>
        </Box>
        <TableWrapper
          small={small}
          header="Reports"
          textColor={primaryText}
          boxPad={{ horizontal: '1.5rem', top: '1.5rem' }}
          tableControls={(
            <TableControls
              small={small}
              focusHighlight={focusHighlight}
              primaryText={primaryText}
              displayInputValue={null}
              selectedSortOption={sortOrder}
              handleSort={(sortDirection) => handleSort(sortDirection)}
              iconPath={`images/sort-input-icon-${sortOrder.direction}.svg`}
            />
          )}
          round="10px"
          border={{
            side: 'all', color: '#AAAAAA', size: '1px', style: 'solid',
          }}
        >
          {renderReports()}
        </TableWrapper>
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    companyId: state.auth.companyId,
  };
}

CustomReportsListPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedCover: PropTypes.string.isRequired,
          embedId: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          filterById: PropTypes.bool,
          isFavorite: PropTypes.bool,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  })),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(withProductAuth(CustomReportsListPage));
