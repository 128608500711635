import styled from 'styled-components';

import ReactTooltip from 'react-tooltip';
import {
  Box, Text, TextInput,
} from 'grommet';

const StyledStatusBadge = styled(Box)`
  text-align: center;
  polyline { stroke-width: 3px; }
  border-radius: 6px;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  height: fit-content;
  min-width: fit-content;

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    max-height: 2.25rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  background: white;
  font-size: 0.8rem;
  border: solid 1px #D0D0D0;
  padding: 0.4rem 0.6rem;
  border-radius: 0.35rem;
  width: 100%;

  &:focus {
    border-color: ${({ focusHighlight }) => focusHighlight};
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  font-family: inherit;
  border-radius: 6px;
`;

const StyledHoverText = styled(Text)`
  &:hover {
    cursor: pointer;
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export {
  StyledStatusBadge,
  StyledTextInput,
  StyledTooltip,
  StyledHoverText,
};
