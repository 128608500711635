import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import uniqid from 'uniqid';

import {
  Box, TableBody, TableCell, TableHeader, TableRow, Text,
} from 'grommet';
import { CircleInformation } from 'grommet-icons';

import { DropButton } from '@Components/Control';
import { buildDateTimeLabel, formatUnit } from '@Helpers/util';
import {
  renderTableHeader, NoDataPlaceholder, TableWrapper,
} from '../SharedComponents';
import {
  StyledPagedTableRowLabel,
  StyledPagedTableUrl,
  StyledDetailTable,
  StyledDetailTableRow,
  StyledDropMenu,
  StyledDetailDropMenu,
  StyledAppButton,
  StyledExtraUrlContainer,
  StyledExtraUrlButton,
} from './StyledDashboard';


const renderText = (text, size, textColor) => (
  <StyledPagedTableRowLabel
    key={uniqid()}
    size={size}
    weight={400}
    color={textColor}
  >
    {text}
  </StyledPagedTableRowLabel>
);

const renderUrl = (value) => <StyledPagedTableUrl color="#252C32" href={value} target="_blank">{value}</StyledPagedTableUrl>;

const renderDetailDropdown = (cellData) => {
  if (cellData.moreDetails) {
    return (
      <DropButton
        key={uniqid()}
        overridePosition={{ top: 'bottom' }}
        withCloseDropContent={(onClose) => (
          <Box round="0.375rem" pad="small">
            <StyledDetailDropMenu direction="column" gap="0.5rem" onClick={() => onClose()}>
              <Text weight={600}>{cellData.moreDetails.summary}</Text>
              <Box direction="row" gap="0.25rem">
                {cellData.moreDetails.details.map((dt) => (
                  <Box key={uniqid()} width={{ min: '15.25rem' }}>
                    <Text>{dt}</Text>
                  </Box>
                ))}
              </Box>
            </StyledDetailDropMenu>
          </Box>
        )}
      >
        {() => (
          <StyledAppButton
            dropDown
            noPadLeft
            alignSelf="start"
            justify="start"
            color="#585858"
            fontWeight={600}
            label={cellData.value}
            icon={<CircleInformation color="#038BFC" size="0.9rem" />}
          />
        )}
      </DropButton>
    );
  }

  return cellData.value;
};

const renderMultiUrls = ({
  url: primaryUrl, postUrl, referenceUrl, secondaryUrl,
}) => {
  const renderRowData = (label, data) => (
    <Box direction="row" gap="0.5rem" align="center">
      <Text wordBreak="keep-all">{`${label}:`}</Text>
      <StyledPagedTableUrl color="#252C32" href={data} target="_blank">{data}</StyledPagedTableUrl>
    </Box>
  );

  return (
    <Box direction="column" gap="0.5rem">
      <StyledPagedTableUrl color="#252C32" href={primaryUrl} target="_blank">{primaryUrl}</StyledPagedTableUrl>
      {(postUrl || referenceUrl || secondaryUrl) && (
        <DropButton
          key={uniqid()}
          overridePosition={{ top: 'bottom' }}
          style={{ width: 'fit-content' }}
          withCloseDropContent={(onClose) => (
            <Box round="0.375rem" pad="small" width={{ max: '25rem' }}>
              <StyledDetailDropMenu direction="column" gap="0.5rem" onClick={() => onClose()}>
                <StyledExtraUrlContainer gap="0.35rem">
                  {postUrl && (renderRowData('Post URL', postUrl))}
                  {referenceUrl && (renderRowData('Reference URL', referenceUrl))}
                  {secondaryUrl && (renderRowData('Secondary URL', secondaryUrl))}
                </StyledExtraUrlContainer>
              </StyledDetailDropMenu>
            </Box>
          )}
        >
          {() => (
            <StyledExtraUrlButton>
              Additional URLs
            </StyledExtraUrlButton>
          )}
        </DropButton>
      )}
    </Box>
  );
};

const CellDropMenu = ({
  options, onClick, textColor, hoverTextColor, hoverBg,
}) => (
  <Box round="6px" pad="small">
    {options.map((option) => (
      <StyledDropMenu
        plain
        key={uniqid()}
        label={option.display}
        onClick={() => onClick(option)}
        textColor={textColor}
        hoverTextColor={hoverTextColor}
        hoverIndicator={hoverBg}
      />
    ))}
  </Box>
);

CellDropMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string.isRequired,
    api: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  onClick: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
  hoverTextColor: PropTypes.string.isRequired,
  hoverBg: PropTypes.string.isRequired,
};

const ClickableTableCell = ({
  colUid,
  size,
  content,
  editableOptions = null,
  editableId = null,
  reviewValue = null,
  updateRowReviewStatus,
  textColor,
  hoverTextColor,
  hoverBg,
  colSpan = null,
  withStatus = false,
}) => {
  const initialValue = reviewValue === 'None' ? { display: 'None', api: 'none' } : reviewValue;
  if ((colUid === 'reviewStatus')) {
    const [selectedOption, setSelectedOption] = React.useState(initialValue);

    const handleSelect = (option, onClose) => {
      setSelectedOption(option);
      updateRowReviewStatus(editableId, option.api);
      onClose();
    };

    const findDisplayValue = () => {
      const foundValue = _.find(editableOptions, { api: reviewValue });

      if (reviewValue === 'pending') {
        return 'Pending';
      }

      if (foundValue && foundValue.display) {
        return foundValue.display;
      }

      return foundValue;
    };

    return (
      <TableCell key={colUid} align={withStatus ? 'end' : 'start'} size={size} colSpan={colSpan}>
        <Box direction="row" gap="1rem" align="center" justify={withStatus ? 'end' : 'start'}>
          {withStatus && (
            <Box>
              <Text weight={500} color="#84919A">Status:</Text>
            </Box>
          )}
          <DropButton
            withCloseDropContent={(onClose) => (
              <CellDropMenu
                options={editableOptions}
                onClick={(option) => handleSelect(option, onClose)}
                textColor={textColor}
                hoverBg={hoverBg}
                hoverTextColor={hoverTextColor}
              />
            )}
          >
            {(Icon) => (
              <StyledAppButton
                dropDown
                color="#585858"
                fontWeight={600}
                label={selectedOption.display || findDisplayValue()}
                icon={<Icon color={textColor} size="0.9rem" />}
              />
            )}
          </DropButton>
        </Box>
      </TableCell>
    );
  }

  return (
    <TableCell key={colUid} align="start" size={size}>
      {content}
    </TableCell>
  );
};

ClickableTableCell.propTypes = {
  colUid: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  editableOptions: PropTypes.arrayOf(PropTypes.shape({
    api: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  })),
  editableId: PropTypes.string,
  reviewValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  updateRowReviewStatus: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
  hoverTextColor: PropTypes.string.isRequired,
  hoverBg: PropTypes.string.isRequired,
  colSpan: PropTypes.number,
  withStatus: PropTypes.bool,
};

const DetailTableRow = ({
  isSmall,
  header,
  rowData,
  updateRowReviewStatus,
  selectedStatuses,
  textColor,
  hoverTextColor,
  hoverBg,
}) => {
  const noDataPlaceholder = (val) => ((val === 'pending') ? '...' : ' ');

  const selectReviewValue = ({ editable, api }) => {
    if (selectedStatuses && selectedStatuses.length > 0 && editable === 'dropdown') {
      const editableRow = rowData.find((row) => row.editable && row.editable === 'dropdown');
      const currentStatus = selectedStatuses.find((dt) => dt.id === editableRow.editableId);

      return currentStatus.review_status;
    }

    return api;
  };

  const renderCellContent = ({ display, decoration, unit }) => {
    const cellData = unit ? `${display} ${formatUnit(unit, display)}` : display;
    let overrideDecoration = false;

    if (display && typeof display === 'object') {
      overrideDecoration = true;
    }

    let renderDecor = (val) => val;
    if (decoration === 'url') {
      renderDecor = renderUrl;
    } else if (decoration === 'datetime') {
      renderDecor = buildDateTimeLabel;
    } else if (decoration === 'details/dropdown') {
      renderDecor = renderDetailDropdown;
    } else if (overrideDecoration) {
      renderDecor = renderMultiUrls;
    }

    return renderText(((cellData !== null && cellData !== 'pending') ? renderDecor(cellData) : noDataPlaceholder(cellData)), '0.9rem', textColor);
  };

  const options = rowData.find(({ editable }) => editable === 'dropdown');
  const filteredData = _.filter(rowData, ((dt) => dt.editable !== 'dropdown'));
  const useData = isSmall ? filteredData : rowData;

  return (
    <>
      <StyledDetailTableRow rowColor="white">
        {useData.map((dt) => (
          <React.Fragment key={uniqid()}>
            <ClickableTableCell
              colUid={dt.editable && dt.editable === 'dropdown' ? 'reviewStatus' : uniqid()}
              align="start"
              size="auto"
              content={renderCellContent(dt)}
              editableOptions={dt.editableOptions}
              editableId={dt.editableId}
              reviewValue={selectReviewValue(dt)}
              updateRowReviewStatus={updateRowReviewStatus}
              textColor={textColor}
              hoverTextColor={hoverTextColor}
              hoverBg={hoverBg}
            />
          </React.Fragment>
        ))}
      </StyledDetailTableRow>
      {isSmall && (
        <StyledDetailTableRow isLast rowColor="white">
          <ClickableTableCell
            withStatus
            colUid="reviewStatus"
            size="auto"
            alignCell="end"
            colSpan={header.length}
            content={renderCellContent({ display: 'Status', decoration: 'editable/dropdown' })}
            editableOptions={options.editableOptions}
            editableId={options.editableId}
            reviewValue={selectReviewValue(options)}
            updateRowReviewStatus={updateRowReviewStatus}
            textColor={textColor}
            hoverTextColor={hoverTextColor}
            hoverBg={hoverBg}
          />
        </StyledDetailTableRow>
      )}
    </>
  );
};

/* eslint-disable camelcase */
DetailTableRow.propTypes = {
  isSmall: PropTypes.bool.isRequired,
  header: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string.isRequired,
  })).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.shape({
    api: PropTypes.string,
    decoration: PropTypes.any,
    display: PropTypes.any.isRequired,
    editable: PropTypes.string,
    editableId: PropTypes.string,
    editableOptions: PropTypes.arrayOf(PropTypes.shape({
      api: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })),
    unit: PropTypes.string,
  })).isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    review_status: PropTypes.string.isRequired,
  })).isRequired,
  updateRowReviewStatus: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
  hoverTextColor: PropTypes.string.isRequired,
  hoverBg: PropTypes.string.isRequired,
};
/* eslint-enable camelcase */

const ReviewTable = ({
  size,
  loading,
  reviewData = null,
  reviewDataHeaders = null,
  initializeReviewedRows,
  updateRowReviewStatus,
  selectedStatuses = null,
  handleResultsPerPage = () => null,
  resultsPerPageOptions = null,
  resultsPerPage = null,
  handlePage = null,
  handleFilter = null,
  tableControls = null,
  currentPage = null,
  totalResults = null,
  authPagesConfig: {
    primaryText,
    buttonHighlight,
    selectButtonBg,
  },
  searchQuery,
  showRefresh = false,
  handleRefresh = null,
  customHeader = null,
}) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (reviewData?.length > 0) {
      setData(reviewData);
      initializeReviewedRows(reviewData);
    }
  }, [reviewData]);

  const isSmall = size.includes('small');
  const displayHeader = isSmall ? _.filter(reviewDataHeaders, (({ display }) => display !== 'Review Status')) : reviewDataHeaders;

  const renderTableContents = () => {
    if (!reviewData || (reviewData && reviewData.length === 0) || loading) {
      const showRefreshButton = reviewData && reviewData.length === 0
        && (searchQuery?.filters || showRefresh);
      const label = showRefreshButton ? 'No results found for this query' : 'Searching for infringements, check back soon';

      return (
        <NoDataPlaceholder
          noRound
          noShadow
          showRefreshButton={showRefreshButton}
          refreshQuery={() => (handleRefresh
            ? handleRefresh()
            : handleFilter(null, null, null, false, true)
          )}
          buttonHighlight={buttonHighlight}
          label={label}
          textColor={primaryText}
          backgroundColor="white"
          loading={loading || !reviewData}
          loaderColor={buttonHighlight}
        />
      );
    }

    return (
      <StyledDetailTable>
        <TableHeader>
          <TableRow>
            {displayHeader && displayHeader.map(({ display }) => (
              <TableCell key={uniqid()} scope="col" align="start">
                {renderTableHeader(display, isSmall)}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data && data.map((rowData) => (
            <DetailTableRow
              key={uniqid()}
              isSmall={isSmall}
              header={displayHeader}
              rowData={rowData}
              updateRowReviewStatus={updateRowReviewStatus}
              selectedStatuses={selectedStatuses}
              textColor={primaryText}
              hoverTextColor={buttonHighlight}
              hoverBg={selectButtonBg}
            />
          ))}
        </TableBody>
      </StyledDetailTable>
    );
  };

  return (
    <TableWrapper
      small={isSmall}
      header={customHeader ?? 'Needs Review'}
      textColor={primaryText}
      boxPad={{ horizontal: isSmall ? '1.5rem' : '2rem', top: '1rem', bottom: isSmall ? '1.5rem' : '2rem' }}
      handleResultsPerPage={handleResultsPerPage}
      handlePage={handlePage}
      resultsPerPageOptions={resultsPerPageOptions}
      selectedResultsCount={resultsPerPage}
      hidePagination={!reviewData || (reviewData && reviewData.length === 0) || loading}
      currentPage={currentPage}
      currentResults={reviewData?.length}
      isPaged={handlePage && (totalResults > data?.length)}
      totalResults={totalResults}
      tableControls={tableControls}
    >
      {renderTableContents()}
    </TableWrapper>
  );
};

/* eslint-disable camelcase */
ReviewTable.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'mediumlarge', 'large']).isRequired,
  loading: PropTypes.bool.isRequired,
  reviewData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    api: PropTypes.string,
    decoration: PropTypes.any,
    display: PropTypes.any.isRequired,
    editable: PropTypes.string,
    editableId: PropTypes.string,
    editableOptions: PropTypes.arrayOf(PropTypes.shape({
      api: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })),
    unit: PropTypes.string,
  }))),
  reviewDataHeaders: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string.isRequired,
  })),
  selectedStatuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    review_status: PropTypes.string.isRequired,
  })),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
  }).isRequired,
  initializeReviewedRows: PropTypes.func.isRequired,
  updateRowReviewStatus: PropTypes.func.isRequired,
  handleResultsPerPage: PropTypes.func,
  resultsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  resultsPerPage: PropTypes.number,
  totalResults: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handlePage: PropTypes.func,
  handleFilter: PropTypes.func,
  currentPage: PropTypes.number,
  tableControls: PropTypes.node,
  showRefresh: PropTypes.bool,
  handleRefresh: PropTypes.func,
  searchQuery: PropTypes.shape({
    paging: PropTypes.shape({
      to: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    }),
    sort: PropTypes.shape({
      name: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    filters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      predicate: PropTypes.string.isRequired,
    })),
  }),
  customHeader: PropTypes.string,
};
/* eslint-enable camelcase */

export default ReviewTable;
