import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Image,
  Text,
  Heading,
} from 'grommet';
import { FormDown, FormNext } from 'grommet-icons';
import { usePopper } from 'react-popper';
import { useSpring, animated } from '@react-spring/web';

import {
  AppLayer, ArrowPopover, AppButton, AppAnchor,
} from '@Components/Control';

import { localAsset } from '@Helpers/asset';

import { paths } from '@Components/configs';
import {
  StyledNavbar,
  StyledLogin,
  StyledProfile,
  StyledHomeAnchor,
  StyledMenuAnchor,
  StyledMenuDrop,
  StyledMenuControlsContainer,
  StyledProfileAnchor,
} from './StyledHeader';

import style from './Header.module.scss';

const RedflagLogo = ({
  darkBackground,
  colorLogo = false,
  isSmall = false,
  sitePath = null,
  ...rest
}) => {
  const logoWidth = isSmall ? '11.65rem' : '10.65rem';
  const logoHeight = isSmall ? '3.25rem' : '2.25rem';

  return (
    <StyledHomeAnchor
      href={sitePath ?? paths.root}
      icon={(
        <Box flex={false} width={logoWidth} height={logoHeight}>
          <Image
            src={localAsset('images/logo/logo-full-color.svg')}
            alt="Redflag AI Logo"
            className={(darkBackground && !colorLogo) ? 'image-light' : ''}
            fit="contain"
            width={logoWidth}
            height={logoHeight}
          />
        </Box>
      )}
      {...rest}
    />
  );
};

RedflagLogo.propTypes = {
  darkBackground: PropTypes.bool.isRequired,
  colorLogo: PropTypes.bool,
  isSmall: PropTypes.bool,
  sitePath: PropTypes.string,
};

export const MenuButton = ({ label, buttonColor, ...rest }) => (
  <StyledLogin {...rest}>
    <AppButton
      overrideHover
      level="dynamicLarge"
      color="white"
      bgColor={buttonColor}
      fontWeight={600}
      label={label}
    />
  </StyledLogin>
);

MenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
};

export const MobileMenuButton = ({
  label, path, onClick, buttonColor, icon,
}) => (
  <AppAnchor path={path}>
    <AppButton
      overrideHover
      level="mobileMenu"
      color="white"
      justify="start"
      bgColor={buttonColor}
      fontWeight={600}
      label={(
        <Box direction="row" width="100%" justify="between" pad={{ right: '20px' }}>
          <Text color="white" size="20px">
            {label}
          </Text>
          <Box align="center" justify="center" width="25px" height="25px">
            <FormNext size="25px" color="white" />
          </Box>
        </Box>
      )}
      onClick={() => onClick()}
      icon={icon}
    />
  </AppAnchor>
);

MobileMenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export const ProfileLogo = ({
  small,
  placeholder,
  logoUrl = null,
  outlineColor = null,
  customSize = null,
}) => {
  let profileSize = small ? '3.25rem' : '2.5rem';

  if (customSize) profileSize = customSize;

  const addDefaultSrc = (e) => {
    e.target.src = placeholder;
  };

  return (
    <Box
      round="50%"
      height={profileSize}
      width={profileSize}
      overflow="hidden"
      flex={false}
      border={outlineColor && {
        color: outlineColor,
        size: '1px',
        style: 'solid',
        side: 'all',
      }}
    >
      <Image
        width="100%"
        height="100%"
        alignSelf="center"
        fit="cover"
        src={logoUrl ?? placeholder}
        alt="Company Logo"
        onError={(e) => addDefaultSrc(e)}
      />
    </Box>
  );
};

ProfileLogo.propTypes = {
  small: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  outlineColor: PropTypes.string,
  customSize: PropTypes.string,
};

export const ProfileSection = ({
  title,
  subtitle,
  placeholder,
  logoUrl = null,
  small = false,
  visible = false,
  darkBackground = false,
  outlineColor = null,
  iconColor = '#636363',
  textColor,
}) => {
  const { transform } = useSpring({
    transform: `rotate(${visible ? -180 : 0}deg)`,
    config: { mass: 1.5, tension: 250, friction: 35 },
  });

  return (
    <StyledProfile
      direction="row"
      align="center"
      gap="0.6rem"
    >
      <ProfileLogo
        small={small}
        logoUrl={logoUrl}
        placeholder={placeholder}
        outlineColor={outlineColor}
      />
      <Text color={darkBackground ? 'white' : textColor} textAlign="start">
        <Heading level={4} margin="none">{title}</Heading>
        <Text>{subtitle}</Text>
      </Text>
      {!small && (
        <animated.div style={{ transform, rotate: '0deg', display: 'flex' }}>
          <FormDown size="1.15rem" color={iconColor} />
        </animated.div>
      )}
    </StyledProfile>
  );
};

ProfileSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  logoUrl: PropTypes.string,
  small: PropTypes.bool,
  visible: PropTypes.bool,
  darkBackground: PropTypes.bool,
  outlineColor: PropTypes.string,
};

export const MenuAnchor = ({
  path = '',
  label = null,
  onClick = () => null,
  hoverColor = '',
  ...rest
}) => (
  <StyledMenuAnchor
    path={path}
    label={label}
    onClick={onClick}
    hoverColor={hoverColor}
    {...rest}
  />
);

MenuAnchor.propTypes = {
  path: PropTypes.string,
  label: PropTypes.node,
  onClick: PropTypes.func,
  hoverColor: PropTypes.string,
};

export const MenuDrop = ({
  menuAlign = 'start',
  anchorComp = null,
  profileProps = null,
  navBg,
  children,
  ...rest
}) => {
  const [visible, setVisible] = React.useState(false);
  const [targetRef, setTargetRef] = React.useState(null);
  const [popperRef, setPopperRef] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const { styles, attributes } = usePopper(targetRef, popperRef, {
    placement: 'bottom-start',
    modifiers: [{ name: 'arrow', options: { element: arrowRef } }],
  });

  return (
    <StyledMenuDrop
      ref={setTargetRef}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onTouchStart={() => setVisible(true)}
      navBg={navBg}
    >
      {profileProps ? (
        <StyledProfileAnchor isProfile {...rest}>
          <ProfileSection
            small={profileProps.small}
            visible={visible}
            darkBackground={profileProps.darkBackground}
            outlineColor={profileProps.outlineColor}
            iconColor={profileProps.iconColor}
            title={profileProps.title}
            subtitle={profileProps.subTitle}
            logoUrl={profileProps.logoUrl}
            placeholder={profileProps.placeholder}
            textColor={profileProps.textColor}
          />
        </StyledProfileAnchor>
      ) : (
        <MenuAnchor {...rest}>
          {anchorComp}
        </MenuAnchor>
      )}
      {visible && (
        <ArrowPopover.Container
          ref={setPopperRef}
          align={menuAlign}
          background={navBg}
          round="0.35rem"
          arrowDepth="0.15rem"
          direction="column"
          style={styles.popper}
          {...attributes.popper}
        >
          <ArrowPopover.DirectedArrow ref={setArrowRef} style={styles.arrow} color={navBg} />
          {children(() => setVisible(false))}
        </ArrowPopover.Container>
      )}
    </StyledMenuDrop>
  );
};

MenuDrop.propTypes = {
  menuAlign: PropTypes.string,
  anchorComp: PropTypes.node,
  children: PropTypes.func.isRequired,
  navBg: PropTypes.string.isRequired,
  profileProps: PropTypes.shape({
    darkBackground: PropTypes.bool.isRequired,
    small: PropTypes.bool.isRequired,
    outlineColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
  }),
};

const Navbar = ({
  navbarRef = React.createRef(),
  ...rest
}) => (
  <StyledNavbar
    fill="horizontal"
    gridArea="header"
    direction="row"
    pad={{ horizontal: rest.isSmall ? '0' : '3.5rem' }}
    height="5.375rem"
    gap="1.2rem"
    align="center"
    flex={false}
    ref={navbarRef}
    {...rest}
  />
);

Navbar.propTypes = {
  navbarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export const NavbarAuthLayout = ({
  children,
  navbarRef = React.createRef(),
  isScroll,
  navBg,
  darkMode,
  borderColor = null,
  ...rest
}) => {
  const navBarHeight = isScroll ? '3.5rem' : '4.75rem';

  return (
    <StyledNavbar
      ref={navbarRef}
      background={navBg}
      fill="horizontal"
      gridArea="header"
      direction="row"
      height={navBarHeight}
      gap="1.2rem"
      align="center"
      flex={false}
      border={borderColor && { side: 'bottom', size: '1px', color: borderColor }}
      {...rest}
    >
      <Box pad={{ left: '1.5rem' }}>
        <RedflagLogo darkBackground={darkMode} />
      </Box>
      <Box
        direction="row"
        flex="grow"
        align="center"
        justify="center"
        gap="1.2rem"
      >
        {children}
      </Box>
    </StyledNavbar>
  );
};

NavbarAuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]).isRequired,
  isScroll: PropTypes.bool.isRequired,
  navbarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  darkMode: PropTypes.bool.isRequired,
  navBg: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
};

export const NavbarLayout = ({
  darkBackground,
  children = null,
  simple = false,
  ...rest
}) => (
  <Navbar justify={simple ? 'center' : 'start'} {...rest}>
    <Box pad={{ right: simple ? '0' : '2.5rem' }}>
      <RedflagLogo
        darkBackground={darkBackground}
        sitePath={simple ? 'https://www.redflagai.co' : null}
      />
    </Box>
    {children && (
      <Box
        direction="row"
        flex="grow"
        align="center"
        justify="end"
      >
        {children}
      </Box>
    )}
  </Navbar>
);

NavbarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]),
  darkBackground: PropTypes.bool.isRequired,
  simple: PropTypes.bool,
};

export const NavbarSmallLayout = ({
  children = null,
  secondaryMenuContent = null,
  showOverlay = false,
  toggle = () => null,
  darkBackground,
  navBg = null,
  navBorder = null,
  primaryTextColor = null,
  simple = false,
  ...rest
}) => {
  const iconSrc = darkBackground ? 'images/menu-open-icon-light.svg' : 'images/menu-open-icon-dark.svg';
  const closeIconSrc = darkBackground ? 'images/menu-close-icon-light.svg' : 'images/menu-close-icon.svg';
  const navHeight = 'calc(5.375rem + 1px)';
  let useTextColor = darkBackground ? 'white' : '#3C3C3C';
  if (primaryTextColor) useTextColor = primaryTextColor;

  return (
    <>
      <Navbar isSmall background={navBg} {...rest}>
        <Box width="100%" justify="center" align="start" pad={{ left: '25px' }}>
          <RedflagLogo
            isSmall
            darkBackground={darkBackground}
            sitePath={simple ? 'https://www.redflagai.co' : null}
          />
        </Box>
        {!simple && (
          <StyledMenuControlsContainer>
            <Button
              icon={(
                <Box direction="row" gap="10px">
                  <Text color={darkBackground ? 'white' : '#3C3C3C'} size="1.15rem" style={{ lineHeight: '1.47rem' }}>
                    Menu
                  </Text>
                  <Box flex={false} width="1.5rem" height="1.5rem">
                    <Image
                      src={localAsset(iconSrc)}
                      width="1.5rem"
                      height="1.5rem"
                      fit="contain"
                    />
                  </Box>
                </Box>
              )}
              onClick={toggle}
            />
          </StyledMenuControlsContainer>
        )}
      </Navbar>
      {showOverlay && !simple
        && (
        <AppLayer
          full
          contentFill
          responsive={false}
          animate={false}
          round="0"
        >
          <Navbar isSmall height={navHeight} background={navBg} className={style.navbarNotFixed}>
            <Box
              fill
              direction="row"
              gap="1.2rem"
              align="center"
              justify="center"
              border={{ side: 'bottom', size: '1px', color: navBorder ?? '#D0D0D0' }}
            >
              <Box width="100%" justify="center" align="start" pad={{ left: '25px' }}>
                <RedflagLogo
                  isSmall
                  darkBackground={darkBackground}
                  sitePath={simple ? 'https://www.redflagai.co' : null}
                />
              </Box>
              <StyledMenuControlsContainer>
                <Button
                  icon={(
                    <Box direction="row" gap="10px">
                      <Text color={useTextColor} size="1.15rem" style={{ lineHeight: '1.47rem' }}>
                        Close
                      </Text>
                      <Box flex={false} height="1.5rem" width="1.5rem" pad="0.15rem">
                        <Image
                          src={localAsset(closeIconSrc)}
                          width="1.5rem"
                          height="1.5rem"
                          fit="contain"
                        />
                      </Box>
                    </Box>
                  )}
                  onClick={toggle}
                />
              </StyledMenuControlsContainer>
            </Box>
          </Navbar>
          <Box fill background={navBg}>
            {children && (
              <Box flex justify="between" gap="1.2rem">
                <Box fill>
                  {children}
                </Box>
              </Box>
            )}
            {secondaryMenuContent && (
              <Box fill="horizontal">
                {secondaryMenuContent}
              </Box>
            )}
          </Box>
        </AppLayer>
        )}
    </>
  );
};

NavbarSmallLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]),
  secondaryMenuContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node,
  ]),
  showOverlay: PropTypes.bool,
  toggle: PropTypes.func,
  darkBackground: PropTypes.bool.isRequired,
  simple: PropTypes.bool,
  navBg: PropTypes.string,
  navBorder: PropTypes.string,
  primaryTextColor: PropTypes.string,
};
