import {
  FAVICON_CLEAR,
  FAVICON_LOADING,
  FAVICON_READY,
} from './actionTypes';

// Reducer corresponding actions
function faviconReset() {
  return { type: FAVICON_CLEAR };
}

function faviconLoading() {
  return { type: FAVICON_LOADING };
}

function faviconReady() {
  return { type: FAVICON_READY };
}

// Private: only to be dispatched within actions
export function setFaviconLoading() {
  return (dispatch) => {
    dispatch(faviconLoading());
  };
}

export function setFaviconReady() {
  return (dispatch) => {
    dispatch(faviconReady());
  };
}

// Public components actions
export function setFaviconDefault() {
  return (dispatch) => {
    dispatch(faviconReset());
  };
}
