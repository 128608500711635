import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContentLoader from 'react-content-loader';

const StyledContentLoader = styled((props) => <ContentLoader {...props} />)`
  max-width: 100%;
`;

const CustomContentLoader = ({ children, ...rest }) => (
  <StyledContentLoader preserveAspectRatio="none" {...rest}>
    {children}
  </StyledContentLoader>
);

CustomContentLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

const PDF = () => (
  <CustomContentLoader width="100%" height="100%" viewBox="0 0 1550 2192.5">
    <rect x="0" y="0" rx="4" ry="4" width="1550" height="2192.5" />
  </CustomContentLoader>
);

const Pricing = {
  TierDefinitionHeader: () => (
    <CustomContentLoader width="8.9rem" height="2.05rem" viewBox="0 0 178 41">
      <rect x="0" y="0" rx="4" ry="4" width="178" height="41" />
    </CustomContentLoader>
  ),
  TierDefinitionCell: () => (
    <CustomContentLoader width="42.65rem" height="4.7rem" viewBox="0 0 853 94">
      <rect x="0" y="0" rx="4" ry="4" width="853" height="28" />
      <rect x="0" y="33" rx="4" ry="4" width="853" height="28" />
      <rect x="0" y="66" rx="4" ry="4" width="400" height="28" />
    </CustomContentLoader>
  ),
};

const InsightPricing = {
  TierTitle: () => (
    <CustomContentLoader width="9rem" height="3.4rem" viewBox="0 0 180 68">
      <rect x="0" y="0" rx="4" ry="4" width="180" height="68" />
    </CustomContentLoader>
  ),
  TierPrice: () => (
    <CustomContentLoader width="11.4rem" height="1.9rem" viewBox="0 0 228 38">
      <rect x="0" y="0" rx="4" ry="4" width="228" height="38" />
    </CustomContentLoader>
  ),
  TierDetail: () => (
    <CustomContentLoader width="100%" height="1.4rem" viewBox="0 0 432 28">
      <rect x="0" y="0" rx="4" ry="4" width="432" height="28" />
    </CustomContentLoader>
  ),
  TierButton: () => (
    <CustomContentLoader width="7.8125rem" height="2.8125rem" viewBox="0 0 156 54">
      <rect x="0" y="0" rx="4" ry="4" width="156" height="54" />
    </CustomContentLoader>
  ),
};

const ApiPricing = {
  Support: () => (
    <CustomContentLoader width="100%" height="2.7rem" viewBox="0 0 1296 54">
      <rect x="0" y="0" rx="4" ry="4" width="1296" height="25" />
      <rect x="60" y="29" rx="4" ry="4" width="1176" height="25" />
    </CustomContentLoader>
  ),
  AnalysisTitle: () => (
    <CustomContentLoader width="7rem" height="1.9rem" viewBox="0 0 140 38">
      <rect x="0" y="0" rx="4" ry="4" width="140" height="39" />
    </CustomContentLoader>
  ),
  AnalysisSupport: () => (
    <CustomContentLoader width="100%" height="2.7rem" viewBox="0 0 1296 54">
      <rect x="0" y="0" rx="4" ry="4" width="1296" height="25" />
      <rect x="0" y="29" rx="4" ry="4" width="1176" height="25" />
    </CustomContentLoader>
  ),
  AnalysisTableHeader: () => (
    <CustomContentLoader width="4rem" height="1rem" viewBox="0 0 80 20">
      <rect x="0" y="0" rx="4" ry="4" width="80" height="20" />
    </CustomContentLoader>
  ),
  AnalysisTableBody: () => (
    <CustomContentLoader width="7rem" height="1rem" viewBox="0 0 140 20">
      <rect x="0" y="0" rx="4" ry="4" width="140" height="20" />
    </CustomContentLoader>
  ),
  AnalysisButton: () => (
    <CustomContentLoader width="7.8125rem" height="2.8125rem" viewBox="0 0 156 54">
      <rect x="0" y="0" rx="4" ry="4" width="156" height="54" />
    </CustomContentLoader>
  ),
};

const Payment = {
  TierTitle: () => (
    <CustomContentLoader width="10.25rem" height="1.24rem" viewBox="0 0 205 24.8">
      <rect x="0" y="0" rx="4" ry="4" width="205" height="24.8" />
    </CustomContentLoader>
  ),
  CardInput: () => (
    <CustomContentLoader width="100%" height="2rem" viewBox="0 0 605 40">
      <rect x="0" y="0" rx="4" ry="4" width="605" height="40" />
    </CustomContentLoader>
  ),
  PayButton: () => (
    <CustomContentLoader width="100%" height="4rem" viewBox="0 0 605 80">
      <rect x="0" y="24" rx="4" ry="4" width="605" height="56" />
    </CustomContentLoader>
  ),
};

const SignUp = {
  ProductInfo: () => (
    <CustomContentLoader width="100%" height="1.24rem" viewBox="0 0 205 24.8">
      <rect x="0" y="0" rx="4" ry="4" width="205" height="24.8" />
    </CustomContentLoader>
  ),
};

const Account = {
  Title: () => (
    <CustomContentLoader width="9.2rem" height="2.3rem" viewBox="0 0 184 46">
      <rect x="0" y="0" rx="4" ry="4" width="184" height="46" />
    </CustomContentLoader>
  ),
  InfoTitle: () => (
    <CustomContentLoader width="12.5rem" height="1.4rem" viewBox="0 0 250 28">
      <rect x="0" y="0" rx="4" ry="4" width="250" height="28" />
    </CustomContentLoader>
  ),
  InfoType: () => (
    <CustomContentLoader width="12.5rem" height="4.6rem" viewBox="0 0 250 92">
      <rect x="0" y="30" rx="4" ry="4" width="250" height="62" />
    </CustomContentLoader>
  ),
  Info: () => (
    <CustomContentLoader width="49rem" height="9rem" viewBox="0 0 980 180">
      <rect x="0" y="30" rx="4" ry="4" width="360" height="30" />
      <rect x="480" y="30" rx="4" ry="4" width="500" height="30" />
      <rect x="0" y="120" rx="4" ry="4" width="360" height="30" />
      <rect x="480" y="120" rx="4" ry="4" width="500" height="30" />
    </CustomContentLoader>
  ),
};

const Usage = ({ thick = false }) => (thick ? (
  <CustomContentLoader width="16.875rem" height="3.4375rem" viewBox="0 0 270 55">
    <rect x="0" y="0" rx="4" ry="4" width="270" height="25" />
    <rect x="0" y="30" rx="4" ry="4" width="270" height="25" />
  </CustomContentLoader>
) : (
  <CustomContentLoader width="10.5rem" height="2.15rem" viewBox="0 0 210 43">
    <rect x="0" y="0" rx="4" ry="4" width="210" height="20" />
    <rect x="0" y="23" rx="4" ry="4" width="210" height="20" />
  </CustomContentLoader>
));

Usage.propTypes = {
  thick: PropTypes.bool,
};

const ListSearch = {
  Keywords: () => (
    <CustomContentLoader width="7.5rem" height="1.1rem" viewBox="0 0 150 22">
      <rect x="0" y="0" rx="4" ry="4" width="150" height="22" />
    </CustomContentLoader>
  ),
  Location: () => (
    <CustomContentLoader width="10rem" height="1.1rem" viewBox="0 0 200 22">
      <rect x="0" y="0" rx="4" ry="4" width="200" height="22" />
    </CustomContentLoader>
  ),
  Date: () => (
    <CustomContentLoader width="10rem" height="1.1rem" viewBox="0 0 200 22">
      <rect x="0" y="0" rx="4" ry="4" width="200" height="22" />
    </CustomContentLoader>
  ),
  Badges: () => (
    <CustomContentLoader width="4.5rem" height="1.3625rem" viewBox="0 0 73 27.25">
      <rect x="0" y="0" rx="8" ry="8" width="73" height="27.25" />
    </CustomContentLoader>
  ),
  Value: () => (
    <CustomContentLoader width="2.5rem" height="1.1rem" viewBox="0 0 50 22">
      <rect x="0" y="0" rx="4" ry="4" width="50" height="22" />
    </CustomContentLoader>
  ),
  Text: () => (
    <CustomContentLoader width="5rem" height="1.1rem" viewBox="0 0 100 22">
      <rect x="0" y="0" rx="4" ry="4" width="100" height="22" />
    </CustomContentLoader>
  ),
  Period: () => (
    <CustomContentLoader width="15rem" height="1.4rem" viewBox="0 0 300 28">
      <rect x="0" y="0" rx="4" ry="4" width="300" height="28" />
    </CustomContentLoader>
  ),
  Control: () => (
    <CustomContentLoader width="6.6rem" height="1.24rem" viewBox="0 0 132 24.8">
      <rect x="0" y="0" rx="4" ry="4" width="132" height="24.8" />
    </CustomContentLoader>
  ),
  UsageTitle: () => (
    <CustomContentLoader width="10rem" height="1.4rem" viewBox="0 0 200 28">
      <rect x="0" y="0" rx="4" ry="4" width="200" height="28" />
    </CustomContentLoader>
  ),
  Usage: () => (
    <CustomContentLoader width="5rem" height="1.4rem" viewBox="0 0 100 28">
      <rect x="0" y="0" rx="4" ry="4" width="100" height="28" />
    </CustomContentLoader>
  ),
  StatusTabs: () => (
    <CustomContentLoader width="5.563rem" height="1.4rem" viewBox="0 0 89 28">
      <rect x="0" y="0" rx="4" ry="4" width="89" height="28" />
    </CustomContentLoader>
  ),
};

const NewSearch = {
  QuestionLabel: () => (
    <CustomContentLoader width="9.0625rem" height="1.625rem" viewBox="0 0 145 26">
      <rect x="0" y="0" rx="4" ry="4" width="19" height="25.5" />
      <rect x="25" y="0" rx="4" ry="4" width="120" height="25.5" />
    </CustomContentLoader>
  ),
  QuestionInput: () => (
    <CustomContentLoader
      width="29.0625rem"
      height="2.1875rem"
      viewBox="0 0 465 35"
      style={{ maxWidth: '100%' }}
    >
      <rect x="0" y="0" rx="4" ry="4" width="465" height="35" />
    </CustomContentLoader>
  ),
  SubmitButton: () => (
    <CustomContentLoader width="8.5rem" height="3.325rem" viewBox="0 0 136 53.2">
      <rect x="0" y="16" rx="4" ry="4" width="136" height="53.2" />
    </CustomContentLoader>
  ),
};

const Dashboard = {
  Title: () => (
    <CustomContentLoader width="7.5rem" height="1rem" viewBox="0 0 150 20">
      <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
    </CustomContentLoader>
  ),
  TitleStatus: () => (
    <CustomContentLoader width="16rem" height="2rem" viewBox="0 0 327 47">
      <rect x="0" y="0" rx="4" ry="4" width="220" height="47" />
      <rect x="242" y="12" rx="4" ry="4" width="85" height="22" />
    </CustomContentLoader>
  ),
  OneThirdWidget: () => (
    <CustomContentLoader width="100%" height="6rem" viewBox="0 0 430 120">
      <rect x="35" y="35" rx="4" ry="4" width="360" height="50" />
    </CustomContentLoader>
  ),
  HalfWidget: () => (
    <CustomContentLoader width="100%" height={384} viewBox="0 0 820 384">
      <rect x="0" y="0" rx="4" ry="4" width="820" height="384" />
    </CustomContentLoader>
  ),
  FullWidget: () => (
    <CustomContentLoader width="100%" height={384} viewBox="0 0 1700 384">
      <rect x="0" y="0" rx="4" ry="4" width="1700" height="384" />
    </CustomContentLoader>
  ),
  SmallWidget: () => (
    <CustomContentLoader width="100%" height="13.5rem" viewBox="0 0 374 216">
      <rect x="0" y="0" rx="4" ry="4" width="374" height="216" />
    </CustomContentLoader>
  ),
  FeatureButton: () => (
    <CustomContentLoader width="14.5rem" height="2rem" viewBox="0 0 320 40">
      <rect x="0" y="0" rx="4" ry="4" width="240" height="40" />
      <circle cx="258.5" cy="12.5" r="12.5" />
    </CustomContentLoader>
  ),
  ExportButton: () => (
    <CustomContentLoader width="6.4rem" height="1.25rem" viewBox="0 0 128 25">
      <rect x="0" y="0" rx="3" ry="3" width="128" height="25" />
    </CustomContentLoader>
  ),
};

const InsightDashboard = {
  FilterRequest: () => (
    <CustomContentLoader width="8rem" height="2.1875rem" viewBox="0 0 128 35">
      <rect x="0" y="0" rx="6" ry="6" width="128" height="35" />
    </CustomContentLoader>
  ),
  SelectRequest: () => (
    <CustomContentLoader width="17rem" height="4.6rem" viewBox="0 0 272 74">
      <rect x="0" y="0" rx="3" ry="3" width="272" height="32" />
      <rect x="0" y="42" rx="3" ry="3" width="272" height="32" />
    </CustomContentLoader>
  ),
};

const BlacklightDashboard = {
  Badges: () => (
    <CustomContentLoader width="9.3rem" height="1.6rem" viewBox="0 0 186 32">
      <rect x="0" y="0" rx="4" ry="4" width="90" height="32" />
      <rect x="96" y="0" rx="4" ry="4" width="90" height="32" />
    </CustomContentLoader>
  ),
  SelectRequest: () => (
    <CustomContentLoader width="19.5rem" height="1.25rem" viewBox="0 0 390 25">
      <rect x="0" y="0" rx="3" ry="3" width="390" height="25" />
    </CustomContentLoader>
  ),
};

const BullseyeDashboard = {
  FilterRequest: () => (
    <CustomContentLoader width="10rem" height="2.1875rem" viewBox="0 0 160 35">
      <rect x="0" y="0" rx="6" ry="6" width="160" height="35" />
    </CustomContentLoader>
  ),
};

export default {
  PDF,
  Pricing,
  InsightPricing,
  ApiPricing,
  Payment,
  SignUp,
  Account,
  Usage,
  ListSearch,
  NewSearch,
  Dashboard,
  InsightDashboard,
  BlacklightDashboard,
  BullseyeDashboard,
};
