import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import {
  Box, Text, Image,
} from 'grommet';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { CurrentDateContext } from '@Components/Context';
import { paths } from '@Components/configs';
import { localAsset } from '@Helpers/asset';

import {
  StyledCyclopsHeading, StyledEventButton, StyledSVG,
} from './StyledCyclopsComponents';


const CyclopsEventsListPage = ({
  small,
  mixpanel,
  location,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyCyclopsData = null,
}) => {
  const { status } = useParams();
  const { today } = React.useContext(CurrentDateContext);
  const formattedDate = today.toLocaleDateString('en-US', {
    month: 'short', day: '2-digit', year: 'numeric',
  });

  const {
    darkModeColors: {
      primaryDarkBg, primaryText, secondaryText,
    },
  } = authPagesConfig;
  let eventsData = null;
  let displayEvents = null;

  if (companyCyclopsData) {
    eventsData = companyCyclopsData.metadata.config.eventsData;
    displayEvents = _.filter(eventsData.events, { status });
  }

  const pageTitle = status === 'live' ? 'Live Events' : 'Past Events';

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box flex background={primaryDarkBg} pad={small ? '2rem' : '3rem'} direction="column" gap="1rem">
        <StyledCyclopsHeading level={2} color={primaryText}>
          {pageTitle}
        </StyledCyclopsHeading>
        {status === 'live' && (
          <Text color={secondaryText} size="1rem" weight={500}>
            {formattedDate}
          </Text>
        )}
        <Box wrap direction="row" justify="start" pad={{ top: status === 'past' ? '1rem' : '0' }}>
          {displayEvents && displayEvents.map(({
            uid, title, image, category, eventDate,
          }) => (
            <StyledEventButton plain key={uid} href={paths.cyclopsEventDetails.replace(':id', uid)}>
              <Box
                direction="column"
                align="start"
                gap="0.5rem"
                margin={{ right: small ? '0' : '2.5rem', bottom: '2.5rem' }}
              >
                <Box className="imageContainer">
                  {status === 'live' && (
                    <StyledSVG
                      className="liveBadge"
                      src={localAsset('images/live-event-badge-icon.svg')}
                      height="1rem"
                      width="2.5rem"
                    />
                  )}
                  <Image src={image} alt={title} fit="cover" />
                </Box>
                <Text color={secondaryText} size="0.9rem" weight={500}>
                  {status === 'live' ? category : eventDate}
                </Text>
                <Text color={primaryText} size="1.25rem" weight={600}>
                  {title}
                </Text>
                {status === 'past' && (
                  <Text color={primaryText} size="0.9rem" weight={400}>
                    {category}
                  </Text>
                )}
              </Box>
            </StyledEventButton>
          ))}
        </Box>
      </Box>
    </AuthNavWrapper>
  );
};

CyclopsEventsListPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyCyclopsData: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        companyName: PropTypes.string.isRequired,
        eventsData: PropTypes.shape({
          events: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            uid: PropTypes.number.isRequired,
            category: PropTypes.string.isRequired,
            eventDate: PropTypes.string,
            image: PropTypes.string.isRequired,
          }).isRequired).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

export default withProductAuth(CyclopsEventsListPage);
