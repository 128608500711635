import {
  PAYMENT_TIER_LOADED,
  PAYMENT_TIER_CLEAR,
  CHECKOUT_REQUESTED,
  CHECKOUT_CLEAR,
} from '../actions/actionTypes';

const initialState = {
  tier: null,
  checkOutLoading: false,
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_TIER_LOADED:
      return { ...state, tier: action.payload.tier };

    case PAYMENT_TIER_CLEAR:
      return { ...state, tier: null };

    case CHECKOUT_REQUESTED:
      return { ...state, checkOutLoading: true };

    case CHECKOUT_CLEAR:
      return { ...state, checkOutLoading: false };

    default:
      return state;
  }
};

export default payment;
