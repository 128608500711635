import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { ReactTags } from 'react-tag-autocomplete';
import { Box } from 'grommet';

import { TagButton } from './ProductFormComponent';

const formatSuggestions = (options) => options.map((dt) => ({ value: uniqid(), label: dt }));

const MultipleSuggestionInputs = ({
  maxInputs = null, options, handleFormValues,
}) => {
  const [tags, setTags] = React.useState([]);
  const [suggestions, setSuggestions] = React.useState(formatSuggestions(options));
  const inputComponent = React.useRef();

  const handleDelete = (index) => {
    const filteredTags = tags.filter((_, i) => i !== index);
    const filteredSuggestions = [...suggestions, tags.find((_, i) => i === index)];
    handleFormValues(filteredTags.map(({ label }) => label));
    setSuggestions(filteredSuggestions);
    setTags(filteredTags);
  };

  const handleAddition = (newTag) => {
    const addedTags = [...tags, newTag];
    const filteredSuggestions = suggestions.filter((dt) => (
      !addedTags.some((p) => p.label === dt.label)));

    if (!maxInputs || tags.length < maxInputs) {
      handleFormValues(addedTags.map(({ label }) => label));
      setSuggestions(filteredSuggestions);
      setTags(addedTags);
    }
  };

  return (
    <Box className="authTagsContainer">
      <ReactTags
        collapseOnSelect
        allowBackspace={tags.length > 0}
        ref={inputComponent}
        onAdd={(tag) => handleAddition(tag)}
        onDelete={(i) => handleDelete(i)}
        onShouldExpand={(value) => value.length >= 1}
        selected={tags}
        renderTag={TagButton}
        suggestions={suggestions}
        placeholderText=""
      />
    </Box>
  );
};

MultipleSuggestionInputs.propTypes = {
  maxInputs: PropTypes.number,
  handleFormValues: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default MultipleSuggestionInputs;
