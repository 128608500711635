import { FETCH_LOADER_LOADING, FETCH_LOADER_FINISHED } from './actionTypes';

// Reducer corresponding actions
function fetchLoaderReset() {
  return { type: FETCH_LOADER_FINISHED };
}

function fetchLoaderLoading() {
  return { type: FETCH_LOADER_LOADING };
}

// Private: only to be dispatched within actions
// for use in GET requests for main page data only
export function setFetchLoaderReset() {
  return (dispatch) => {
    dispatch(fetchLoaderReset());
  };
}

export function setFetchLoaderLoading() {
  return (dispatch) => {
    dispatch(fetchLoaderLoading());
  };
}
