import styled from 'styled-components';

import { Box } from 'grommet';
import { AppButton } from '@Components/Control';

const StyledFrameContainer = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: fit-content;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const StyledSortButton = styled(AppButton)`
  background: white;
  font-size: 0.8rem;
  padding: 0.3rem;
  border-radius: 5px;
  border: 1px solid #D0D0D0;
  width: 6rem;
  height: 1.85rem;

  &:focus {
    border: ${({ highlight }) => `1px solid ${highlight}`};
  }

  > div {
    border-radius: 0.35rem;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
  }

  svg {
    padding-top: 0.25rem;
  }
`;

export {
  StyledFrameContainer,
  StyledSortButton,
};
