import React from 'react';
import { createRoot } from 'react-dom/client';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { StyleSheetManager } from 'styled-components';

import 'regenerator-runtime/runtime';

import GlobalFont from './styles/GlobalFont';
import App from './App';

import '@Theme/scss/index.scss';

import store from './store/configureStore';

const persistor = persistStore(store);

const appContainer = document.getElementById('app');
const root = createRoot(appContainer);

if (root) {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyleSheetManager enableVendorPrefixes>
          <GlobalFont />
          <App />
        </StyleSheetManager>
      </PersistGate>
    </Provider>,
  );
}
