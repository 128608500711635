import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'grommet';


const SystemStatusNotice = ({
  statusMessageConfig: {
    message,
    textColor,
    bgColor,
    statusDotColor,
  },
  gapSize = '0.5rem',
  textSize = '0.9rem',
  ...rest
}) => (
  <Box direction="row" gap={gapSize} justify="center" align="center" pad="0.5rem" background={bgColor} round="8px" {...rest}>
    <Box
      width="0.5rem"
      height="0.5rem"
      style={{ minWidth: '0.5rem', minHeight: '0.5rem' }}
      background={statusDotColor}
      round="full"
    />
    <Text size={textSize} color={textColor} weight={600}>
      {message}
    </Text>
  </Box>
);

SystemStatusNotice.propTypes = {
  statusMessageConfig: PropTypes.shape({
    message: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    statusDotColor: PropTypes.string.isRequired,
  }),
  gapSize: PropTypes.string,
  textSize: PropTypes.string,
};

export default SystemStatusNotice;
