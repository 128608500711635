import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'grommet';

const ResponsiveImage = ({
  src, srcMobile, fit = null, ...rest
}) => (
  <picture>
    <source media="(max-width: 48em)" srcSet={srcMobile} />
    <source media="(min-width: 48.0625em)" srcSet={src} />
    <Image src={src} fit={fit} width="100%" height="100%" {...rest} />
  </picture>

);

ResponsiveImage.propTypes = {
  src: PropTypes.node.isRequired,
  srcMobile: PropTypes.node.isRequired,
  fit: PropTypes.string,
};

export default ResponsiveImage;
