import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Text, Image,
} from 'grommet';
import { Chat } from 'grommet-icons';

import { PrivacyPolicyModal, TermsOfUseModal } from '@Components/Modal';
import { CurrentDateContext } from '@Components/Context';
import { SystemStatusNotice } from '@Components/Control';

import { localAsset } from '@Helpers/asset';

import { StyledModalButton, StyledMailtoAnchor } from './StyledFooter';


const AuthFooter = ({
  small,
  mixpanel,
  config: {
    lowerBgColor,
    lowerTextColor,
    secondaryLowerTextColor,
    highlightColor,
  },
  authPagesConfig: {
    showStatusMessage,
    statusMessageConfig,
    darkModeColors,
  },
  darkMode = false,
}) => {
  const [showPrivacyModal, setShowPrivacyModal] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);

  const { today } = React.useContext(CurrentDateContext);
  const currentYear = today.getUTCFullYear();

  const togglePrivacy = () => {
    setShowPrivacyModal(!showPrivacyModal);
  };

  const toggleTerms = () => {
    setShowTermsModal(!showTermsModal);
  };

  const textSize = small ? '0.9rem' : '0.75rem';
  const gapSize = small ? '0.5rem' : '0.3rem';
  const bgColor = darkMode ? darkModeColors.containerBg : lowerBgColor;
  const mainTextColor = darkMode ? darkModeColors.primaryText : lowerTextColor;
  const secondaryTextColor = darkMode ? darkModeColors.primaryText : secondaryLowerTextColor;
  const highlightTextColor = darkMode ? darkModeColors.secondaryText : highlightColor;
  const logoSrc = darkMode ? 'images/logo/logo-image-only-white.svg' : 'images/logo/logo-image-only-color.svg';

  return (
    <Box
      fill="horizontal"
      align="center"
      justify="center"
      flex={false}
      pad={{ vertical: small ? '1rem' : '0.75rem' }}
      background={bgColor}
      gap="1rem"
      direction={small ? 'column-reverse' : 'column'}
    >
      {showPrivacyModal
        && (
        <PrivacyPolicyModal
          toggle={() => togglePrivacy()}
          isSmall={small}
          mixpanel={mixpanel}
          currentYear={currentYear}
          background={bgColor}
          textColor={secondaryTextColor}
        />
        )}
      {showTermsModal
        && (
        <TermsOfUseModal
          toggle={() => toggleTerms()}
          isSmall={small}
          mixpanel={mixpanel}
          currentYear={currentYear}
          background={bgColor}
          textColor={secondaryTextColor}
        />
        )}
      <Box direction="row-responsive" gap={gapSize} justify="center" align="center">
        <Box direction="row" gap={gapSize} justify="center" align="center">
          <Box justify="center" align="center" width={small ? '1rem' : '0.85rem'} height={small ? '1rem' : '0.85rem'}>
            <Image
              fit="contain"
              src={localAsset(logoSrc)}
              alt="Redflag AI logo"
              width={small ? '1rem' : '0.85rem'}
              height={small ? '1rem' : '0.85rem'}
            />
          </Box>
          <Box>
            <Text weight={600} margin="none" size={textSize} color={mainTextColor}>
              ©
              {' '}
              {currentYear}
              {' '}
              Redflag Artificial Intelligence Inc. All rights reserved.
            </Text>
          </Box>
        </Box>
        <Box direction="row" gap={gapSize} justify="center" align="center" pad={{ top: small ? '0.3rem' : '0' }}>
          {!small && (<Text size={textSize} color={secondaryTextColor}>|</Text>)}
          <StyledModalButton
            plain
            onClick={() => togglePrivacy()}
            color={secondaryTextColor}
            highlight={highlightTextColor}
          >
            <Box flex justify="center" align="center">
              <Text size={textSize}>Privacy Policy</Text>
            </Box>
          </StyledModalButton>
          <Text size={textSize} color={secondaryTextColor}>|</Text>
          <StyledModalButton
            plain
            onClick={() => toggleTerms()}
            color={secondaryTextColor}
            highlight={highlightTextColor}
          >
            <Box flex justify="center" align="center">
              <Text size={textSize}>Terms of Use</Text>
            </Box>
          </StyledModalButton>
          <Text size={textSize} color={secondaryTextColor}>|</Text>
          <Box direction="row" gap={gapSize} justify="center" align="center">
            <Box>
              <Chat size="0.7rem" color={secondaryTextColor} />
            </Box>
            <StyledMailtoAnchor
              href="mailto:contact@redflagai.co"
              target="_blank"
              color={secondaryTextColor}
              highlight={highlightTextColor}
            >
              <Box flex justify="center" align="center">
                <Text size={textSize}>Send Feedback</Text>
              </Box>
            </StyledMailtoAnchor>
          </Box>
        </Box>
      </Box>
      {showStatusMessage && statusMessageConfig && (
        <SystemStatusNotice
          statusMessageConfig={statusMessageConfig}
          gapSize={gapSize}
          textSize={textSize}
        />
      )}
    </Box>
  );
};

AuthFooter.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    lowerBgColor: PropTypes.string.isRequired,
    lowerTextColor: PropTypes.string.isRequired,
    secondaryLowerTextColor: PropTypes.string.isRequired,
    highlightColor: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    showStatusMessage: PropTypes.bool,
    statusMessageConfig: PropTypes.shape({
      message: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      statusDotColor: PropTypes.string.isRequired,
    }),
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
    }).isRequired,
  }),
  darkMode: PropTypes.bool,
};

export default AuthFooter;
