import {
  PRODUCT_NEW_FORM_CONFIG_LOADED,
  PRODUCT_NEW_FORM_CONFIG_CLEAR,
  PRODUCT_NEW_SEARCHES_USAGE_LOADING,
  PRODUCT_NEW_SEARCHES_USAGE_LOADED,
  PRODUCT_NEW_SEARCHES_USAGE_CLEAR,
} from '../actionTypes';
import * as api from '../apiHelper';
import { initiateRedirectRequest } from '../redirectActions';
import { setFetchLoaderLoading, setFetchLoaderReset } from '../fetchLoaderActions';
import { arriveNotice } from '../noticeActions';

// Reducer corresponding actions
function newFormConfigClear() {
  return { type: PRODUCT_NEW_FORM_CONFIG_CLEAR };
}

function newFormConfigLoaded(formConfig) {
  return {
    type: PRODUCT_NEW_FORM_CONFIG_LOADED,
    payload: { formConfig },
  };
}

function productUsageLoading() {
  return { type: PRODUCT_NEW_SEARCHES_USAGE_LOADING };
}

function productUsageLoaded(productUsageData) {
  return {
    type: PRODUCT_NEW_SEARCHES_USAGE_LOADED,
    payload: { productUsageData },
  };
}

function productUsageClear() {
  return { type: PRODUCT_NEW_SEARCHES_USAGE_CLEAR };
}

// API
async function getNewFormConfigApi(body, token) {
  const response = await api.get('search_types', body, token, 'v3');
  return response.json();
}

async function submitNewSearchApi(body, token) {
  const response = await api.post('searches', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function submitSearchFeatureApi(id, feature, args, token) {
  const response = await api.post(`searches/${id}/${feature}`, args, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function getProductUsageApi(token) {
  const response = await api.get('products/blacklight/usages', null, token, 'v2');
  return response.json();
}

// Public components actions
export function initiateNewFormConfigRequest(body) {
  return async (dispatch, getState) => {
    try {
      dispatch(newFormConfigClear());

      const { auth: { token }, fetchLoader: { dataLoading } } = getState();
      if (!dataLoading) dispatch(setFetchLoaderLoading());

      const response = await getNewFormConfigApi(body, token);
      dispatch(newFormConfigLoaded(response));
      dispatch(setFetchLoaderReset());
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(setFetchLoaderReset());
        dispatch(arriveNotice(e.message));
      }
    }
  };
}

export function submitNewSearchRequest(query, rootPath, resetForm) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      const { notice } = await submitNewSearchApi(query, token);

      dispatch(arriveNotice(notice, 'ok'));
      resetForm();
      dispatch(initiateRedirectRequest(null, rootPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function submitSearchFeatureRequest(id, feature, redirectPath, args) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      const { notice } = await submitSearchFeatureApi(id, feature, args, token);

      dispatch(arriveNotice(notice, 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function clearProductUsageRequest() {
  return (dispatch) => {
    dispatch(productUsageClear());
  };
}

export function initiateProductUsageRequest() {
  return async (dispatch, getState) => {
    try {
      dispatch(productUsageClear());
      dispatch(productUsageLoading());

      const { token } = getState().auth;
      const json = await getProductUsageApi(token);
      dispatch(productUsageLoaded(json));
    } catch (e) {
      if (e.name !== 'AbortError') {
        dispatch(arriveNotice(e.message));
      }
    }
  };
}
