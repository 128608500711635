import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import {
  Box, Layer, Button, Text,
} from 'grommet';
import {
  FormClose,
  StatusGood,
  StatusWarning,
  Refresh,
  Checkmark,
} from 'grommet-icons';

import { closeNoticeRequest } from '@Actions';

const DURATION = 5000;
const bgColorMap = { warning: 'status-warning', ok: 'status-ok', refresh: '#507FD9' };
const textColorMap = { warning: 'var(--primary-text)', ok: 'var(--primary-text)', refresh: 'white' };
const iconMap = {
  warning: <StatusWarning size="1.5rem" color="white" />,
  ok: <StatusGood size="1.5rem" color="white" />,
  refresh: <Refresh size="1.5rem" color="white" />,
};

export const StyledLayer = styled(Layer)`
  ${({ transparent }) => transparent && 'background: transparent;'}
`;

const handleRefresh = () => {
  window.location.reload();
};

const NoticeButton = ({
  onClick, type, children, ...rest
}) => (
  <Button plain onClick={onClick}>
    <Box
      round
      overflow="hidden"
      pad="xxsmall"
      border={{ color: textColorMap[type] }}
      {...rest}
    >
      {children}
    </Box>
  </Button>
);

NoticeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const NotifyContainer = ({
  message, type, children = null, closeNotice,
}) => (
  <StyledLayer
    transparent
    position="bottom-left"
    modal={false}
    responsive={false}
  >
    <Box
      align="center"
      direction="row"
      pad="0.75rem"
      margin="0.75rem"
      round="10px"
      background={bgColorMap[type]}
    >
      {iconMap[type]}
      <Box direction="row" align="center">
        <Text margin={{ horizontal: '0.75rem' }} color={textColorMap[type]} size="1.1rem">{message}</Text>
        {children}
        <NoticeButton onClick={closeNotice} type={type}>
          <FormClose size="small" color={textColorMap[type]} />
        </NoticeButton>
      </Box>
    </Box>
  </StyledLayer>
);

NotifyContainer.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closeNotice: PropTypes.func.isRequired,
};

const RefreshNotify = ({ type, ...rest }) => (
  <NotifyContainer type={type} {...rest}>
    <NoticeButton onClick={() => handleRefresh()} type={type} margin={{ horizontal: '1rem' }}>
      <Checkmark size="small" color={textColorMap[type]} />
    </NoticeButton>
  </NotifyContainer>
);

RefreshNotify.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closeNotice: PropTypes.func.isRequired,
};

class Notification extends React.Component {
  componentDidMount() {
    const { message, type } = this.props;

    if (type === 'ok') {
      if (message) this.startTimer();
      else this.stopTimer();
    }
  }

  componentDidUpdate() {
    const { message, type } = this.props;

    if (type === 'ok') {
      if (message) this.startTimer();
      else this.stopTimer();
    }
  }

  componentWillUnmount() {
    const { type, closeNotice } = this.props;
    this.stopTimer();

    if (type === 'refresh') closeNotice();
  }

  startTimer() {
    const { closeNotice } = this.props;
    this.timer = setTimeout(closeNotice, DURATION);
  }

  stopTimer() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = undefined;
  }

  render() {
    const { message, type } = this.props;

    if (message && type && type in iconMap) {
      if (type === 'refresh') {
        return <RefreshNotify {...this.props} />;
      }
      return <NotifyContainer {...this.props} />;
    }

    return null;
  }
}

function mapStateToProps(state) {
  const { message, type } = state.notice;
  return { message, type };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeNotice: closeNoticeRequest }, dispatch);
}

Notification.defaultProps = {
  message: null,
  type: null,
};

Notification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  closeNotice: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
