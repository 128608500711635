import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Heading, Button } from 'grommet';
import { Close } from 'grommet-icons';

import { AppLayer } from '@Components/Control';

export const StyledModalContainer = styled(Box)`
  h1, h2, h3, p, span, a, li, strong {
    color: ${({ textColor }) => textColor};
  }
`;

const ModalRenderer = ({
  toggle, isSmall, title, renderContent, background, textColor,
}) => {
  const radius = isSmall ? '0' : null;

  return (
    <AppLayer
      animate={false}
      responsive={false}
      full={isSmall}
      margin={isSmall ? 'none' : 'large'}
      round={radius}
      onClickOutside={toggle}
      background={background}
    >
      <Box
        flex={false}
        direction="row"
        justify="between"
        border={{ color: 'light-3', side: 'bottom', size: 'xsmall' }}
      >
        <Heading level={3} margin="medium" color={textColor}>
          {title}
        </Heading>
        <Box justify="center" pad="small">
          <Button icon={<Close />} onClick={toggle} />
        </Box>
      </Box>
      <StyledModalContainer textColor={textColor}>
        {renderContent()}
      </StyledModalContainer>
    </AppLayer>
  );
};

ModalRenderer.propTypes = {
  toggle: PropTypes.func.isRequired,
  isSmall: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  renderContent: PropTypes.func.isRequired,
  background: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default ModalRenderer;
