import {
  REDIRECT_REQUESTED,
  REDIRECT_COMPLETED,
} from './actionTypes';

// Reducer corresponding actions
function redirectRequested(path, pathname, push) {
  return {
    type: REDIRECT_REQUESTED,
    payload: { path, pathname, push },
  };
}

function redirectCompleted() {
  return { type: REDIRECT_COMPLETED };
}

// Private: only to be dispatched within actions
export function initiateRedirectRequest(path, pathname, push = false) {
  return (dispatch) => {
    dispatch(redirectRequested(path, pathname, push));
  };
}

// Public components actions
export function clearRedirectRequest() {
  return (dispatch) => {
    dispatch(redirectCompleted());
  };
}
