import React from 'react';
import PropTypes from 'prop-types';

import { Box, Heading } from 'grommet';

import {
  Seo, FullHeightBox, ResponsiveImage, Container,
} from '@Components/Control';

import { localAsset } from '@Helpers/asset';

const Error404Page = ({ mixpanel }) => {
  React.useEffect(() => {
    mixpanel.track('Page View', { Page: 'Error 404' });
  }, []);

  return (
    <FullHeightBox fill justify="center" background="white">
      <Seo />
      <Container align="center" gap="large" pad="medium">
        <Box flex={false} width="20rem">
          <ResponsiveImage
            src={localAsset('images/logo/logo-full-color.svg')}
            srcMobile={localAsset('images/logo/logo-full-color.svg')}
            alt="Redflag Logo"
          />
        </Box>
        <Box gap="medium" align="center">
          <Heading level={1} size="large" textAlign="center" margin="none">
            404
          </Heading>
          <Heading thin level={2} size="large" textAlign="center" margin="none">
            Page Not Found
          </Heading>
        </Box>
      </Container>
    </FullHeightBox>
  );
};

Error404Page.propTypes = {
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
};

export default Error404Page;
