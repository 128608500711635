import { APPLOADER_CLEAR, APPLOADER_READY } from './actionTypes';

// Reducer corresponding actions
function appLoaderReset() {
  return { type: APPLOADER_CLEAR };
}

function appLoaderReady() {
  return { type: APPLOADER_READY };
}

// Private: only to be dispatched within actions
export function setAppLoaderReset() {
  return (dispatch) => {
    dispatch(appLoaderReset());
  };
}

export function setAppLoaderReady() {
  return (dispatch) => {
    dispatch(appLoaderReady());
  };
}
