export default {
  root: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  recoverAccount: '/recover-account',
  account: '/account',
  productNewSearch: '/new',
  productReview: '/review',
  productListSearches: '/searches/:status',
  productSearchResults: '/results/:id',
  productFlexibleSearches: '/dynamic-searches',
  productFlexibleSearchResults: '/dynamic-searches/:id',
  productPiracyOverview: '/piracy-overview',
  productCustomReports: '/custom-reports',
  productCustomReport: '/custom-reports/:id',
  productSentimentOverview: '/sentiment-overview',
  cyclopsAnalytics: '/cyclops/analytics',
  cyclopsEventsList: '/cyclops/events/:status',
  cyclopsEventDetails: '/cyclops/events/results/:id',
  cyclopsSettings: '/cyclops/settings',
};
