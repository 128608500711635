import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text, Image, Button,
} from 'grommet';
import { Checkmark } from 'grommet-icons';

import { Seo, RadioToggleButton } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { localAsset } from '@Helpers/asset';

import { FilesTable } from '../../../Partial/Product/CyclopsComponents';
import {
  StyledCyclopsContainer, StyledCyclopsHeading, StyledHr, StyledSelectionContainer, StyledSVG,
} from './StyledCyclopsComponents';


const SelectionCard = ({
  small,
  title,
  description,
  primaryText,
  highlightColor,
  containerBg,
  imageUrl,
  selected,
  fileUid,
  handleSelect,
  removable,
  handleRemove,
}) => (
  <Box direction="column" gap="0.5rem">
    <StyledSelectionContainer
      onClick={() => handleSelect(fileUid)}
      selected={selected}
      highlightColor={highlightColor}
    >
      <Box
        border={{ color: 'white', size: '2px' }}
        background={selected ? '#038BFC' : containerBg}
        width={small ? '2rem' : '1.25rem'}
        height={small ? '2rem' : '1.25rem'}
        justify="center"
        align="center"
        round="50%"
        className="checkMark"
      >
        {selected && <Checkmark color="white" size={small ? '1.25rem' : '0.8rem'} />}
      </Box>
      <Box background={containerBg} direction="column" gap="0.5rem" width="100%" height={small ? '17rem' : '12.5rem'}>
        <Box flex round="10px" pad={{ bottom: '0.5rem' }}>
          <Image src={imageUrl} fit="cover" style={{ borderRadius: '10px' }} />
        </Box>
      </Box>
    </StyledSelectionContainer>
    <Box direction="row" justify="between">
      <Text size="1.1rem" weight={500} color={primaryText}>{title}</Text>
      {removable && (
        <Button plain onClick={handleRemove(fileUid)}>
          <StyledSVG
            src={localAsset('images/delete-content-icon.svg')}
            height={small ? '1.25rem' : '1rem'}
            width={small ? '1.25rem' : '1rem'}
            $fillColor={highlightColor}
          />
        </Button>
      )}
    </Box>
    <Box width={small ? '100%' : '65%'}>
      <Text size="0.9rem" weight={400} color={primaryText}>{description}</Text>
    </Box>
  </Box>
);

SelectionCard.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fileUid: PropTypes.number.isRequired,
  primaryText: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  removable: PropTypes.bool,
  handleRemove: PropTypes.func.isRequired,
};

const FeatureContainer = ({
  small,
  title,
  description,
  primaryText,
  containerBg,
  children,
  toggleValue = null,
}) => (
  <StyledCyclopsContainer background={containerBg} direction="column" gap="1rem" width={small ? '100%' : '85%'}>
    <Box direction="row" justify="between" align="center">
      <Text size="1.1rem" weight={500} color={primaryText}>{title}</Text>
      {toggleValue !== null && (
        <RadioToggleButton
          key={uniqid()}
          inactiveBg="#D9D9D9"
          activeBg="#004FFE"
          toggleBg="white"
          initialValue={toggleValue}
        />
      )}
    </Box>
    <Box width={small ? '100%' : '65%'}>
      <Text size="0.9rem" weight={400} color={primaryText}>{description}</Text>
    </Box>
    {children}
  </StyledCyclopsContainer>
);

FeatureContainer.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  toggleValue: PropTypes.bool,
};

const CyclopsSettingsPage = ({
  small,
  mixpanel,
  location,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyCyclopsData = null,
}) => {
  const {
    darkModeColors: {
      primaryDarkBg, containerBg, primaryText, secondaryText,
    },
  } = authPagesConfig;
  let settingsData = null;
  let featuresData = null;
  let contentOverrideData = null;
  let eventSchedulesData = null;
  let detectionSitesData = null;

  if (!companyCyclopsData) return null;

  if (companyCyclopsData) {
    settingsData = companyCyclopsData.metadata.config.settingsData;
    featuresData = settingsData.features;

    contentOverrideData = featuresData.find(({ key }) => key === 'contentOverride');
    eventSchedulesData = featuresData.find(({ key }) => key === 'eventSchedules');
    detectionSitesData = featuresData.find(({ key }) => key === 'detectionSites');
  }

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box flex background={primaryDarkBg} pad={small ? '2rem' : '3rem'} direction="column" gap="1.5rem">
        <StyledCyclopsHeading level={2} color={primaryText}>
          Cyclops Settings
        </StyledCyclopsHeading>
        <StyledCyclopsContainer background={containerBg} direction="column" gap="0.5rem" width={small ? '75%' : '45%'}>
          <Box direction="row" justify="between" align="center">
            <Text size="1.1rem" weight={600} color={primaryText}>CDNs:</Text>
            <Text size="1.1rem" weight={600} color={primaryText}>{settingsData.activeCDN}</Text>
          </Box>
          <StyledHr color="#D0D0D0" />
          <Box direction="row" justify="between" align="center">
            <Text size="1.1rem" weight={600} color={primaryText}>Encoder:</Text>
            <Text size="1.1rem" weight={600} color={primaryText}>{settingsData.activeEncoder}</Text>
          </Box>
        </StyledCyclopsContainer>
        <Box direction="column" gap="1rem">
          <Text size="1rem" color={secondaryText} weight={500}>
            Features
          </Text>
          <Box direction="column" gap="2rem">
            {detectionSitesData && (
              <FeatureContainer
                key={uniqid()}
                small={small}
                title="Detection"
                description="Enable monitoring of known piracy sites. Detection activates after uploading at least one CSV file."
                primaryText={primaryText}
                containerBg={containerBg}
                toggleValue={detectionSitesData.files.length > 0}
              >
                <FilesTable
                  small={small}
                  textColor={primaryText}
                  headerColor={secondaryText}
                  filesData={detectionSitesData.files}
                  handleUpload={() => null}
                />
              </FeatureContainer>
            )}
            {eventSchedulesData && (
              <FeatureContainer
                key={uniqid()}
                small={small}
                title="Livestream Shutdown and Event Schedule"
                description="Enable Livestream Shutdown by uploading a schedule of your upcoming events. This allows us to monitor and shut down unauthorized streams in real time."
                primaryText={primaryText}
                containerBg={containerBg}
                toggleValue={eventSchedulesData.files.length > 0}
              >
                <FilesTable
                  small={small}
                  textColor={primaryText}
                  headerColor={secondaryText}
                  filesData={eventSchedulesData.files}
                  handleUpload={() => null}
                />
              </FeatureContainer>
            )}
            {contentOverrideData && (
              <FeatureContainer
                key={uniqid()}
                small={small}
                title="Content Override"
                description="Replace piracy streams with custom content."
                primaryText={primaryText}
                containerBg={containerBg}
              >
                <Box wrap direction="row" gap="1rem">
                  {contentOverrideData.files.map(({
                    fileName, fileUid, description, fileUrl, selected, removable,
                  }) => (
                    <Box basis={small ? 'full' : '1/3'} pad={{ vertical: '1rem', horizontal: small ? '0' : '1rem' }} margin={{ left: small ? '0' : '-1rem' }} key={fileUid}>
                      <SelectionCard
                        small={small}
                        title={fileName}
                        description={description}
                        fileUid={fileUid}
                        primaryText={primaryText}
                        highlightColor={secondaryText}
                        containerBg={containerBg}
                        imageUrl={fileUrl}
                        selected={selected}
                        removable={removable}
                        handleSelect={() => null}
                        handleRemove={() => null}
                      />
                    </Box>
                  ))}
                </Box>
              </FeatureContainer>
            )}
          </Box>
        </Box>
      </Box>
    </AuthNavWrapper>
  );
};

CyclopsSettingsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyCyclopsData: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        companyName: PropTypes.string.isRequired,
        settingsData: PropTypes.shape({
          activeCDN: PropTypes.string.isRequired,
          activeEncoder: PropTypes.string.isRequired,
          features: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            files: PropTypes.arrayOf(PropTypes.shape({
              fileName: PropTypes.string.isRequired,
              fileUid: PropTypes.number.isRequired,
              description: PropTypes.string,
              fileUrl: PropTypes.string.isRequired,
              fileSize: PropTypes.string,
              uploadDate: PropTypes.string,
              selected: PropTypes.bool,
              removable: PropTypes.bool,
            })).isRequired,
          }).isRequired).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

export default withProductAuth(CyclopsSettingsPage);
