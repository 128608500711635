import SubmissionLayout from './SubmissionLayout';

export {
  StyledTitle as StyledSubmissionTitle,
  StyledFieldRow as StyledSubmissionFieldRow,
  StyledField as StyledSubmissionField,
  StyledAnchor as StyledSubmissionAnchor,
  StyledButton as StyledSubmissionButton,
  StyledFormInput as StyledSubmissionInput,
} from './StyledSubmissionComponent';
export default SubmissionLayout;
