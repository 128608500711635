import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { authRefreshRequest, closeNoticeRequest, resetAbortController } from '@Actions';

export default (WrappedComponent) => {
  const ProductAuthLayout = ({
    isAuthenticated, notice: { type }, refreshUser, closeNotice, ...rest
  }) => {
    React.useEffect(() => {
      async function awaitUserRefresh() {
        if (isAuthenticated) await refreshUser();
      }

      if (type === 'warning') closeNotice();
      awaitUserRefresh();

      return () => {
        resetAbortController();
      };
    }, []);

    return <WrappedComponent {...rest} />;
  };

  function mapStateToProps(state) {
    return { isAuthenticated: state.auth.isAuthenticated, notice: state.notice };
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      refreshUser: authRefreshRequest,
      closeNotice: closeNoticeRequest,
    }, dispatch);
  }

  ProductAuthLayout.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    notice: PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
    refreshUser: PropTypes.func.isRequired,
    closeNotice: PropTypes.func.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(ProductAuthLayout);
};
