import {
  PRODUCT_DASHBOARD_REVIEW_DATA_LOADED,
  PRODUCT_DASHBOARD_REVIEW_DATA_CLEAR,
  PRODUCT_DASHBOARD_REVIEW_STATS_LOADED,
  PRODUCT_DASHBOARD_REVIEW_STATS_CLEAR,
  PRODUCT_DASHBOARD_REVIEW_META_LOADED,
  PRODUCT_DASHBOARD_REVIEW_META_CLEAR,
  PRODUCT_DASHBOARD_FLEXIBLE_DATA_LOADED,
  PRODUCT_DASHBOARD_FLEXIBLE_DATA_CLEAR,
  PRODUCT_DASHBOARD_SEARCH_RESULTS_LOADED,
  PRODUCT_DASHBOARD_SEARCH_RESULTS_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  productReviewData: null,
  productReviewStats: null,
  productReviewMeta: null,
  productFlexibleReviewData: null,
  productSearchResultsData: null,
};

const dashboardBlacklight = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DASHBOARD_REVIEW_DATA_LOADED:
      return { ...state, productReviewData: action.payload.result };

    case PRODUCT_DASHBOARD_REVIEW_DATA_CLEAR:
      return initialState;

    case PRODUCT_DASHBOARD_REVIEW_STATS_LOADED:
      return { ...state, productReviewStats: action.payload.result };

    case PRODUCT_DASHBOARD_REVIEW_STATS_CLEAR:
      return initialState;

    case PRODUCT_DASHBOARD_REVIEW_META_LOADED:
      return { ...state, productReviewMeta: action.payload.result };

    case PRODUCT_DASHBOARD_REVIEW_META_CLEAR:
      return initialState;

    case PRODUCT_DASHBOARD_FLEXIBLE_DATA_LOADED:
      return { ...state, productFlexibleReviewData: action.payload.result };

    case PRODUCT_DASHBOARD_FLEXIBLE_DATA_CLEAR:
      return initialState;

    case PRODUCT_DASHBOARD_SEARCH_RESULTS_LOADED:
      return { ...state, productSearchResultsData: action.payload.result };

    case PRODUCT_DASHBOARD_SEARCH_RESULTS_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default dashboardBlacklight;
