import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import Case from 'case';

import { Box } from 'grommet';

import { AppButton } from '@Components/Control';
import {
  StyledFormSelect,
  StyledFormSelectMenu,
} from './StyledProductFormComponent';

const SelectMenu = ({
  options = null,
  simpleOptions = null,
  onClick,
  selectButtonBg,
  highlightText,
  ...rest
}) => {
  if (simpleOptions && !options) {
    return (
      <Box round="0.35rem" pad="12px">
        {simpleOptions.map((val) => (
          <StyledFormSelectMenu
            plain
            key={uniqid()}
            label={Case.capital(val)}
            onClick={() => onClick(val)}
            hoverIndicator={selectButtonBg}
            highlightText={highlightText}
            {...rest}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box round="0.35rem" pad="12px">
      {options.map(({ name }) => (
        <StyledFormSelectMenu
          plain
          key={uniqid()}
          label={name}
          onClick={() => onClick(name)}
          hoverIndicator={selectButtonBg}
          highlightText={highlightText}
          {...rest}
        />
      ))}
    </Box>
  );
};

SelectMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired),
  simpleOptions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired),
  onClick: PropTypes.func.isRequired,
  selectButtonBg: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
};

const DropdownSelect = ({
  options = null,
  simpleOptions = null,
  authPagesConfig: {
    selectButtonBg,
    primaryText,
    secondaryText,
    hintText,
    buttonHighlight,
    focusHighlight,
  },
  handleFormValues,
  selectable = null,
  autoSelect = false,
  label = null,
  value = null,
  ...rest
}) => {
  React.useEffect(() => {
    if ((value === null) && autoSelect && simpleOptions && simpleOptions.length > 0) {
      handleFormValues(simpleOptions[0]);
    } else if (value === null && autoSelect) {
      handleFormValues(false);
    }
  }, []);

  let displayLabel = label;
  const selectOptions = selectable ? simpleOptions : [false, true];

  if (selectable && value && !label) {
    displayLabel = Case.capital(value);
  }

  if (!selectable && (value || value === false) && !label) {
    displayLabel = value ? 'True' : 'False';
  }

  return (
    <StyledFormSelect
      noShadow
      formIcons
      highlight={focusHighlight}
      withCloseDropContent={(onClose) => (
        <SelectMenu
          selectButtonBg={selectButtonBg}
          highlightText={buttonHighlight}
          options={options}
          simpleOptions={selectOptions}
          onClick={(val) => {
            handleFormValues(val);
            onClose();
          }}
          {...rest}
        />
      )}
      {...rest}
    >
      {(Icon) => (
        <AppButton
          fill
          dropDown
          textSize="1rem"
          color={displayLabel ? primaryText : hintText}
          label={displayLabel || 'Select an option'}
          icon={<Icon color={secondaryText} size="1.25rem" />}
          {...rest}
        />
      )}
    </StyledFormSelect>
  );
};

DropdownSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired),
  simpleOptions: PropTypes.arrayOf(PropTypes.string),
  handleFormValues: PropTypes.func.isRequired,
  authPagesConfig: PropTypes.shape({
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
  }).isRequired,
  selectable: PropTypes.bool,
  autoSelect: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default DropdownSelect;
