import {
  PRODUCT_DASHBOARD_REVIEW_DATA_LOADED,
  PRODUCT_DASHBOARD_REVIEW_DATA_CLEAR,
  PRODUCT_DASHBOARD_REVIEW_STATS_LOADED,
  PRODUCT_DASHBOARD_REVIEW_STATS_CLEAR,
  PRODUCT_DASHBOARD_REVIEW_META_LOADED,
  PRODUCT_DASHBOARD_REVIEW_META_CLEAR,
  PRODUCT_DASHBOARD_FLEXIBLE_DATA_LOADED,
  PRODUCT_DASHBOARD_FLEXIBLE_DATA_CLEAR,
  PRODUCT_DASHBOARD_SEARCH_RESULTS_LOADED,
  PRODUCT_DASHBOARD_SEARCH_RESULTS_CLEAR,
} from '../actionTypes';

import * as api from '../apiHelper';
import { setFetchLoaderLoading, setFetchLoaderReset } from '../fetchLoaderActions';
import { arriveNotice } from '../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../faviconActions';

// Reducer corresponding actions
function dashboardProductReviewClear() {
  return { type: PRODUCT_DASHBOARD_REVIEW_DATA_CLEAR };
}

function dashboardProductReviewLoaded(result) {
  return {
    type: PRODUCT_DASHBOARD_REVIEW_DATA_LOADED,
    payload: { result },
  };
}

function dashboardProductReviewStatsClear() {
  return { type: PRODUCT_DASHBOARD_REVIEW_STATS_CLEAR };
}

function dashboardProductReviewStatsLoaded(result) {
  return {
    type: PRODUCT_DASHBOARD_REVIEW_STATS_LOADED,
    payload: { result },
  };
}

function dashboardProductReviewMetaClear() {
  return { type: PRODUCT_DASHBOARD_REVIEW_META_CLEAR };
}

function dashboardProductReviewMetaLoaded(result) {
  return {
    type: PRODUCT_DASHBOARD_REVIEW_META_LOADED,
    payload: { result },
  };
}

function dashboardProductFlexibleDataClear() {
  return { type: PRODUCT_DASHBOARD_FLEXIBLE_DATA_CLEAR };
}

function dashboardProductFlexibleDataLoaded(result) {
  return {
    type: PRODUCT_DASHBOARD_FLEXIBLE_DATA_LOADED,
    payload: { result },
  };
}

function dashboardProductSearchResultsClear() {
  return { type: PRODUCT_DASHBOARD_SEARCH_RESULTS_CLEAR };
}

function dashboardProductSearchResultsLoaded(result) {
  return {
    type: PRODUCT_DASHBOARD_SEARCH_RESULTS_LOADED,
    payload: { result },
  };
}

// API
async function getProductReviewApi(args, token) {
  const response = await api.nestedParamsGet('results/review', args, token, 'v3');
  return response.json();
}

async function getProductReviewStatsApi(args, token) {
  const response = await api.get('results/review/stats', args, token, 'v3');
  return response.json();
}

async function getProductReviewMetaApi(args, token) {
  const response = await api.get('results/review/meta', args, token, 'v3');
  return response.json();
}

async function updateProductReviewSelectionsApi(body, token) {
  const response = await api.put('results/review', body, token, 'v3');
  return response;
}

async function getFlexibleReviewDataApi(id, args, token) {
  const response = await api.nestedParamsGet(`flexible_company_data/${id}`, args, token, 'v3');
  return response.json();
}

async function updateProductFlexibleReviewSelectionsApi(body, token) {
  const response = await api.put('flexible_company_data/review', body, token, 'v3');
  return response;
}

async function getSearchResultsDataApi(id, args, token) {
  const response = await api.nestedParamsGet(`searches/${id}`, args, token, 'v3');
  return response.json();
}

// helper
async function fetchProductReview(dispatch, args, token) {
  try {
    const response = await getProductReviewApi(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(dashboardProductReviewLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchProductReviewStats(dispatch, args, token) {
  try {
    const response = await getProductReviewStatsApi(args, token);

    dispatch(setFaviconDefault());
    dispatch(dashboardProductReviewStatsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchProductReviewMeta(dispatch, args, token) {
  try {
    const response = await getProductReviewMetaApi(args, token);

    dispatch(setFaviconDefault());
    dispatch(dashboardProductReviewMetaLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchProductFlexibleReviewData(dispatch, id, args, token) {
  try {
    const response = await getFlexibleReviewDataApi(id, args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(dashboardProductFlexibleDataLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchProductSearchResults(dispatch, id, args, token) {
  try {
    const response = await getSearchResultsDataApi(id, args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(dashboardProductSearchResultsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
export function clearProductDashboardReviewRequest() {
  return (dispatch) => {
    dispatch(dashboardProductReviewClear());
  };
}

export function initiateProductDashboardReviewRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token }, favicon: { faviconStatus }, fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductReview(dispatch, args, token);
  };
}

export function clearProductDashboardReviewStatsRequest() {
  return (dispatch) => {
    dispatch(dashboardProductReviewStatsClear());
  };
}

export function initiateProductDashboardReviewStatsRequest(args) {
  return async (dispatch, getState) => {
    const { auth: { token }, favicon: { faviconStatus } } = getState();

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductReviewStats(dispatch, args, token);
  };
}

export function clearProductDashboardReviewMetaRequest() {
  return (dispatch) => {
    dispatch(dashboardProductReviewMetaClear());
  };
}

export function initiateProductDashboardReviewMetaRequest(args) {
  return async (dispatch, getState) => {
    const { auth: { token }, favicon: { faviconStatus } } = getState();

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductReviewMeta(dispatch, args, token);
  };
}

export function updateProductDashboardReviewSelectionsRequest(body, afterAction) {
  return async (dispatch, getState) => {
    const { auth: { token } } = getState();

    try {
      const { notice } = await updateProductReviewSelectionsApi(body, token);

      dispatch(arriveNotice(notice, 'ok'));
      afterAction();
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function clearProductDashboardFlexibleReviewDataRequest() {
  return (dispatch) => {
    dispatch(dashboardProductFlexibleDataClear());
  };
}

export function initiateProductDashboardFlexibleReviewDataRequest(id, args) {
  return async (dispatch, getState) => {
    const {
      auth: { token }, favicon: { faviconStatus }, fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductFlexibleReviewData(dispatch, id, args, token);
  };
}

export function updateProductDashboardFlexibleReviewSelectionsRequest(body, afterAction) {
  return async (dispatch, getState) => {
    const { auth: { token } } = getState();

    try {
      const { notice } = await updateProductFlexibleReviewSelectionsApi(body, token);

      dispatch(arriveNotice(notice, 'ok'));
      afterAction();
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function clearProductDashboardSearchResultsRequest() {
  return (dispatch) => {
    dispatch(dashboardProductSearchResultsClear());
  };
}

export function initiateProductDashboardSearchResultsRequest(id, args) {
  return async (dispatch, getState) => {
    const {
      auth: { token }, favicon: { faviconStatus }, fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchProductSearchResults(dispatch, id, args, token);
  };
}
