import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Meter, Text,
} from 'grommet';


const PercentageMeter = ({
  small,
  values,
  colorMap,
  direction = 'horizontal',
  ...rest
}) => {
  const valueMap = values.map(({ uid, value, label }) => {
    const color = colorMap[uid];

    return {
      value, label, color,
    };
  });

  return (
    <Box direction="column" align="center" gap="1rem" {...rest}>
      <Meter
        type="bar"
        values={valueMap}
        max={100}
        size="full"
        thickness="large"
        direction={small ? 'horizontal' : direction}
        style={{ borderRadius: '5px' }}
      />
      <Box direction="row" align="center">
        {values.map(({
          display, uid, label,
        }, i) => (
          <Box
            key={uid}
            direction="column"
            gap="0.5rem"
            border={(i !== values.length - 1) && (
              { color: 'white', side: 'right', size: '1px' }
            )}
            pad={{ horizontal: '1.5rem' }}
          >
            <Box direction="row" align="center" gap="0.5rem">
              <Box
                background={colorMap[uid]}
                round="50%"
                height={small ? '0.85rem' : '0.5rem'}
                width={small ? '0.85rem' : '0.5rem'}
              />
              <Text size="1rem" weight={400}>{display}</Text>
            </Box>
            <Box align="center" justify="center">
              <Text size={small ? '1.85rem' : '2rem'} weight={600}>{label}</Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

PercentageMeter.propTypes = {
  small: PropTypes.bool.isRequired,
  direction: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  })).isRequired,
  colorMap: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default PercentageMeter;
