import { FETCH_LOADER_LOADING, FETCH_LOADER_FINISHED } from '../actions/actionTypes';

const initialState = {
  dataLoading: false,
};

const fetchLoader = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOADER_LOADING:
      return { ...state, dataLoading: true };

    case FETCH_LOADER_FINISHED:
      return initialState;

    default:
      return state;
  }
};

export default fetchLoader;
