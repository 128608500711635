export default {
  singleUrlInput: 'SINGLE_URL_INPUT',
  multipleUrlInput: 'MULTIPLE_URL_INPUT',
  singleTextInput: 'SINGLE_TEXT_INPUT',
  multipleTextInput: 'MULTIPLE_TEXT_INPUT',
  multiLinedTextInput: 'MULTI_LINED_TEXT_INPUT',
  multipleGroupInput: 'MULTIPLE_GROUP_INPUT',
  textArea: 'TEXT_AREA',
  datetimeEntry: 'DATETIME_ENTRY',
  multipleDatetimeEntry: 'MULTIPLE_DATETIME_ENTRY',
  radioSelect: 'RADIO_SELECT',
  multiSelect: 'MULTI_SELECT',
  tagsInput: 'TAGS_INPUT',
  suggestionsInput: 'SUGGESTIONS_INPUT',
  singleMediaUpload: 'SINGLE_MEDIA_UPLOAD',
  multipleMediaUpload: 'MULTIPLE_MEDIA_UPLOAD',
};
