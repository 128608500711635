import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { paths } from '@Components/configs';


export const NoAuthWrapper = ({ isAuthenticated, children }) => {
  if (isAuthenticated) {
    return <Navigate replace to={paths.root} />;
  }

  return children;
};

NoAuthWrapper.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const ProductAuthWrapper = ({ isAuthenticated, children }) => {
  if (isAuthenticated) {
    return children;
  }

  return <Navigate replace to={paths.root} />;
};

ProductAuthWrapper.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
