import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import loadable from '@loadable/component';
import Case from 'case';

import { Box, Text } from 'grommet';

import { Seo, PlaceholderLoader } from '@Components/Control';
import SubmissionLayout, { StyledSubmissionTitle } from '@Components/Layout/Submission';

import { fetchPaymentTierRequest, clearPaymentTierRequest, checkoutRequest } from '@Actions';

import { locationSearchParams } from '@Helpers/util';
import { paths } from '@Components/configs';

const Form = loadable(() => import('./Form'));

const FormLoader = () => (
  <Box fill="horizontal">
    <PlaceholderLoader.Payment.CardInput />
    <PlaceholderLoader.Payment.PayButton />
  </Box>
);

const PaymentPage = ({
  location, fetchPaymentTier, clearPaymentTier, checkout, checkOutLoading, tier,
}) => {
  const { products, tier: tierString } = locationSearchParams(location.search);

  React.useEffect(() => {
    const path = paths[`pricing${Case.capital(products)}`];
    fetchPaymentTier(products, tierString, path);

    return () => clearPaymentTier();
  }, []);

  return (
    <SubmissionLayout title="Tier">
      <Seo />
      <Box gap="small">
        {tier ? (
          <Text size="small" margin={{ bottom: 'small' }}>
            <strong>{tier.display}</strong>
    &nbsp;-&nbsp;
            {Case.capital(tier.price)}
            &nbsp;per&nbsp;
            {tier.interval}
          </Text>
        ) : (
          <Box margin={{ bottom: 'small' }}>
            <PlaceholderLoader.Payment.TierTitle />
          </Box>
        )}
        <StyledSubmissionTitle level={4} margin="none">Payment</StyledSubmissionTitle>
        <Form
          fallback={<FormLoader />}
          loader={<FormLoader />}
          checkout={checkout}
          checkOutLoading={checkOutLoading}
          products={products}
          tier={tier}
        />
      </Box>
    </SubmissionLayout>
  );
};

function mapStateToProps(state) {
  return {
    tier: state.payment.tier,
    checkOutLoading: state.payment.checkOutLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPaymentTier: fetchPaymentTierRequest,
    clearPaymentTier: clearPaymentTierRequest,
    checkout: checkoutRequest,
  }, dispatch);
}

PaymentPage.defaultProps = {
  tier: null,
};

PaymentPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  tier: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    interval: PropTypes.string.isRequired,
    immediateCharge: PropTypes.bool.isRequired,
  }),
  checkOutLoading: PropTypes.bool.isRequired,
  fetchPaymentTier: PropTypes.func.isRequired,
  clearPaymentTier: PropTypes.func.isRequired,
  checkout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
