import React from 'react';
import PropTypes from 'prop-types';

import { Box, RadioButtonGroup } from 'grommet';


const RadioToggleButton = ({
  inactiveBg,
  activeBg,
  toggleBg,
  updateParent = () => null,
  initialValue = null,
  ...rest
}) => {
  const [selected, setSelected] = React.useState(initialValue ?? false);

  const handleSelection = (value) => {
    setSelected(value);
    updateParent(value);
  };

  return (
    <Box background={selected ? activeBg : inactiveBg} round="50px" align="center" pad="0.35rem" {...rest}>
      <RadioButtonGroup
        name="radio"
        direction="row"
        gap="0.5rem"
        options={[false, true]}
        value={selected}
        onChange={(event) => handleSelection(event.target.value)}
        toggleBg={toggleBg}
      >
        {(option) => {
          let background = 'rgba(0,0,0,0)';

          if (selected.toString() === option.toString()) background = toggleBg;

          return (
            <Box background={background} round="50%" height="1.35rem" width="1.35rem" />
          );
        }}
      </RadioButtonGroup>
    </Box>
  );
};

RadioToggleButton.propTypes = {
  inactiveBg: PropTypes.string.isRequired,
  activeBg: PropTypes.string.isRequired,
  toggleBg: PropTypes.string.isRequired,
  updateParent: PropTypes.func,
  initialValue: PropTypes.bool,
};

export default RadioToggleButton;
