import React from 'react';
import PropTypes from 'prop-types';

import { startOfDay, format } from 'date-fns';
import { UTCDate } from '@date-fns/utc';

import { Box } from 'grommet';
import { Previous, Next } from 'grommet-icons';
import Calendar from 'react-calendar';

import {
  GridContainer, CalendarText, checkDatePresence,
} from './Component';


export default class DateRangeCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.initialDates.startDate,
      endDate: props.initialDates.endDate,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
  }

  onDatesChange(datesArr) {
    const { setDateRange } = this.props;

    setDateRange(checkDatePresence(datesArr[0]), checkDatePresence(datesArr[1]));
    this.setState({ startDate: datesArr[0], endDate: datesArr[1] });
  }

  render() {
    const { startDate, endDate } = this.state;
    const { bounds, buttonHighlight, className } = this.props;

    let tileDisabled = () => false;
    if (bounds) {
      const [minStartDate, maxEndDate] = bounds;
      const parsedMinStartDate = minStartDate && startOfDay(new UTCDate(minStartDate));
      const parsedMaxEndDate = maxEndDate && startOfDay(new UTCDate(maxEndDate));

      tileDisabled = ({ date }) => {
        const dayStart = startOfDay(new UTCDate(date));

        return parsedMinStartDate >= dayStart || dayStart > parsedMaxEndDate;
      };
    }

    return (
      <Box align="center" pad="small" className={`app-calendar ${className}`}>
        <Calendar
          selectRange
          value={[startDate, endDate]}
          onChange={this.onDatesChange}
          navigationLabel={({ date }) => (
            <Box height="1.75rem" justify="center">
              <CalendarText weight={600} label={format(date, 'MMM yyyy')} />
            </Box>
          )}
          nextLabel={<GridContainer><Next size="small" color={buttonHighlight || '#788af7'} /></GridContainer>}
          next2Label={null}
          prevLabel={<GridContainer><Previous size="small" color={buttonHighlight || '#788af7'} /></GridContainer>}
          prev2Label={null}
          tileContent={({ date }) => (
            <GridContainer>
              <CalendarText label={format(date, 'd')} />
            </GridContainer>
          )}
          formatShortWeekday={(locale, date) => format(date, 'EEEEE')}
          showNeighboringMonth={false}
          view="month"
          minDetail="month"
          tileDisabled={tileDisabled}
        />
      </Box>
    );
  }
}

DateRangeCalendar.defaultProps = {
  bounds: null,
  buttonHighlight: null,
  className: null,
};

DateRangeCalendar.propTypes = {
  setDateRange: PropTypes.func.isRequired,
  initialDates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  bounds: PropTypes.arrayOf(PropTypes.string.isRequired),
  buttonHighlight: PropTypes.string,
  className: PropTypes.string,
};
