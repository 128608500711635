import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { FullHeightBox, AppLoader } from '@Components/Control';
import { Notification } from '@Components/Modal';
import Header from './Header';
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';

const Container = ({
  small,
  authPageActive,
  mixpanel,
  location,
  authPagesConfig = null,
  reportsConfig = null,
  flexibleDataCompany = false,
  searchEnabled = false,
  cyclopsEnabled = false,
  darkMode = false,
}) => {
  const selectHeader = () => {
    if (authPageActive) {
      return (
        <AuthHeader
          small={small}
          darkMode={darkMode}
          authPagesConfig={authPagesConfig}
          reportsConfig={reportsConfig}
          location={location}
          searchEnabled={searchEnabled}
          cyclopsEnabled={cyclopsEnabled}
        />
      );
    }

    return (
      <Header darkHeader small={small} />
    );
  };

  return (
    <>
      {selectHeader()}
      <AppLoader />
      <Notification />
      <FullHeightBox noNav>
        <Outlet />
      </FullHeightBox>
      {(!authPageActive || flexibleDataCompany) && (
        <AuthFooter
          small={small}
          darkMode={darkMode}
          mixpanel={mixpanel}
          config={authPagesConfig.footerConfig}
          authPagesConfig={authPagesConfig}
        />
      )}
    </>
  );
};

Container.propTypes = {
  small: PropTypes.bool.isRequired,
  authPageActive: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  flexibleDataCompany: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  cyclopsEnabled: PropTypes.bool,
  darkMode: PropTypes.bool,
  authPagesConfig: PropTypes.shape({
    headerBg: PropTypes.string.isRequired,
    pageBg: PropTypes.string.isRequired,
    sideNavBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    footerConfig: PropTypes.shape({
      lowerBgColor: PropTypes.string.isRequired,
      lowerTextColor: PropTypes.string.isRequired,
      secondaryLowerTextColor: PropTypes.string.isRequired,
      highlightColor: PropTypes.string.isRequired,
    }).isRequired,
    icons: PropTypes.objectOf(PropTypes.any.isRequired),
    navIcons: PropTypes.objectOf(PropTypes.any.isRequired),
    listMenuMap: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    hideBlacklightSearchCompanies: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    showStatusMessage: PropTypes.bool,
    statusMessageConfig: PropTypes.shape({
      message: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      statusDotColor: PropTypes.string.isRequired,
    }),
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedCover: PropTypes.string.isRequired,
          embedId: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          filterById: PropTypes.bool,
          isFavorite: PropTypes.bool,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  })),
};

export default Container;
