import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Anchor } from 'grommet';

export const StyledAnchor = styled(Anchor)`
  &, &:hover { text-decoration: underline; }

  ${({ buttonHoverColor }) => buttonHoverColor && `
    &, &:hover {
      text-decoration: none;
      background-color: ${buttonHoverColor};
    }
  `}

  ${({ highlightText }) => highlightText && `
    color: ${highlightText};
  `}
`;

const AppAnchor = ({
  forwardRef = null,
  path = null,
  href = null,
  children = null,
  onClick = () => null,
  noDecoration = false,
  buttonHoverColor = null,
  highlightText = null,
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const active = location.pathname === path;

  const AnchorComp = ((active && !noDecoration) ? StyledAnchor : Anchor);
  let clickEvent = (e) => onClick(e);

  if (path) {
    clickEvent = (e) => {
      e.preventDefault();
      if (e.ctrlKey && !active) {
        window.open(path, '_blank');
      } else if (!active) {
        navigate(path);
        window.scrollTo(0, 0);
      }

      onClick(e);
    };
  }

  return (
    <AnchorComp
      ref={forwardRef}
      href={path || href}
      onClick={clickEvent}
      active={active}
      buttonHoverColor={buttonHoverColor}
      highlightText={highlightText}
      {...rest}
    >
      {children}
    </AnchorComp>
  );
};

AppAnchor.propTypes = {
  forwardRef: PropTypes.shape({ current: PropTypes.any }),
  children: PropTypes.node,
  className: PropTypes.string,
  path: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  noDecoration: PropTypes.bool,
  buttonHoverColor: PropTypes.string,
  highlightText: PropTypes.string,
};

export default AppAnchor;
