import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text, RadioButtonGroup } from 'grommet';


const RadioButtonSlider = ({
  radioOptions,
  textColor,
  componentBg,
  activeButtonBg,
  updateParent,
  ...rest
}) => {
  const [selected, setSelected] = React.useState(radioOptions[0].value);

  const handleSelection = (value) => {
    setSelected(value);
    updateParent(value);
  };

  return (
    <Box background={componentBg} round="0.35rem" align="center" pad="0.5rem" {...rest}>
      <RadioButtonGroup
        name="radio"
        direction="row"
        gap="0.5rem"
        options={radioOptions}
        value={selected}
        onChange={(event) => handleSelection(event.target.value)}
      >
        {(option, { checked, focus, hover }) => {
          let background = componentBg;
          if (checked || hover || focus) background = activeButtonBg;
          return (
            <Box background={background} round="0.35rem" pad={{ vertical: '0.35rem', horizontal: '0.6rem' }}>
              <Text size="0.85rem" weight={600} color={textColor}>
                {option.label}
              </Text>
            </Box>
          );
        }}
      </RadioButtonGroup>
    </Box>
  );
};

RadioButtonSlider.propTypes = {
  radioOptions: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  componentBg: PropTypes.string.isRequired,
  activeButtonBg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  updateParent: PropTypes.func.isRequired,
};

export default RadioButtonSlider;
