import styled from 'styled-components';

import { Box } from 'grommet';

const StyledAddedSearchContainer = styled(Box)`
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export default StyledAddedSearchContainer;
